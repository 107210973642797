import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function SetEnglishName() {
  const navigate = useNavigate();

  return (
    <div className="mx-auto">
      <div className="flex justify-between items-center mb-6">
        <div>
          <span className="text-gray-400"></span>
          <h1 className="text-2xl font-semibold text-gray-800">設定英文姓名</h1>
        </div>
      </div>
      <div className="bg-white shadow rounded-lg p-6">
        <div className="overflow-x-auto">
          <div>
            <label className='text-gray-800'>請輸入英文姓名，以做為公司Email帳號：</label>
            <div>
              <input type="text" className="mt-2 px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500" />
            </div>
            <div className='mt-2'>
              <em className='text-sm text-red-500'>英文姓名已存在，可於名字後加入數字，或修改英文姓名。</em>
              <div className='text-sm text-gray-500'>英文姓名格式為:名.姓，如：john.chen</div>
            </div>
          </div>
          <div className="text-right mt-4">
            <button
              onClick={() => navigate('/recruiter')}
              className="btn-save hover:btn-save px-3 py-1 text-white rounded-md"
            >
              確認
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SetEnglishName;
