import React from 'react';
import { useNavigate, Link } from 'react-router-dom';

function UsePersonalDataAgreement() {
  const navigate = useNavigate();

  return (
    <div className="mx-auto">
      <div className="bg-white shadow rounded-lg p-6 max-w-6xl">
        <div className="text-center font-semibold mb-4">蒐集、處理及利用個人資料告知暨同意書</div>
        <p>
          零壹科技股份有限公司為蒐集、處理及利用 員工或應徵者個人資料，茲依據個人資料保護法第8條、第19條、第21條、第3條規定，向員工或應徵者告知下列事項，請詳閱：
        </p>
        <table className='border'>
          <tbody>
            <tr>
              <td className='border p-2 w-1/3'>蒐集、處理及利用特定目的</td>
              <td className='border p-2'>代號○○一、代號○○二、代號○九四、代號○九五、代號一二九、代號一八一</td>
            </tr>
            <tr>
              <td className='border p-2'>個人資料類別</td>
              <td className='border p-2'>
                於主管機管許可保留資料之存續期間內，符合下列要件之一者：<br/>
                1.個人資料蒐集之特定目的存續期間。<br/>
                2.依相關法令規定或契約約定的保存年限。<br/>
                3.因執行業務所必須之保存期間。
              </td>
            </tr>
            <tr>
              <td className='border p-2'>個人資料利用之區域</td>
              <td className='border p-2'>獲主管機關許可經營及經營營業登記項目或章程所定之業務，其使用之相關地區包含本公司與本公司有母子公司(從屬)公司或總分公司或集團關係之公司、與本公司因業務需要而訂有契約之機構等所在之地區。</td>
            </tr>
            <tr>
              <td className='border p-2'>個人資料利用之對象</td>
              <td className='border p-2'>本公司、與本公司有母子(從屬)公司或總分公司或集團關係之公司、與本公司因業務需要而訂有契約之機構等</td>
            </tr>
            <tr>
              <td className='border p-2'>個人資料利用之方式</td>
              <td className='border p-2'>以自動化系統傳輸或其他非自動化方式所為之利用，包含書面或電子。</td>
            </tr>
            <tr>
              <td className='border p-2'>拒絕提供個人資料所致權益之影響</td>
              <td className='border p-2'>拒絕提供本公司因業務需求之個人資料時，本公司將無法進行業務之必要審核與處理作業及其他相關服務，本公司得拒絕受理與員工或應徵者之業務往來及其他相關事項之辦理與申請。</td>
            </tr>
          </tbody>
        </table>
        <div className='mt-4'>
          列表代號請詳閱
          <Link to="/recruiter/personal-data-categories" className='text-blue-500 underline'>附件</Link><br/>
          <input type="checkbox" className='mr-2' />
          經 零壹科技股份有限公司向本人告知上開事項，本人已經清楚了解並同意 貴公司蒐集、處理或利用本人個人資料之目的及用途
        </div>
        <div className="text-right mt-4">
          <button
            onClick={() => navigate('/recruiter')}
            className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 mr-2"
          >
            確認
          </button>
          <button
            onClick={() => navigate('/recruiter')}
            className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            返回
          </button>
        </div>
      </div>
    </div>
  );
}

export default UsePersonalDataAgreement;
