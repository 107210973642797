import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { get, deleteById } from '../../services/api';
import { FiEdit2 } from 'react-icons/fi';
import { RiDeleteBinLine } from "react-icons/ri";

function ReferenceChecks() {
  const [referenceChecks, setReferenceChecks] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [referenceChecksPerPage] = useState(25);
  const [totalReferenceChecks, setTotalReferenceChecks] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();

  const fetchReferenceChecks = useCallback(async (search, page) => {
    setLoading(true);
    setError(null);
    try {
      const response = await get(`/api/ehrm/v1/Interview/${id}/ReferenceChecks`);
      console.log('API 完整響應:', response.data);

      if (response && Array.isArray(response)) {
        setReferenceChecks(response);
        setTotalReferenceChecks(response.length);
      } else {
        console.error('API 響應結構不符合預期:', response.data);
        setReferenceChecks([]);
        setTotalReferenceChecks(0);
        setError('獲取數據時出現問題');
      }
    } catch (error) {
      console.error('獲取失敗:', error);
      setError('獲取列表時發生錯誤');
      setReferenceChecks([]);
      setTotalReferenceChecks(0);
    } finally {
      setLoading(false);
    }
  }, [referenceChecksPerPage]);

  useEffect(() => {
    fetchReferenceChecks(searchTerm, currentPage);
  }, [fetchReferenceChecks, currentPage]);

  const handleDelete = async (id) => {
    if (window.confirm('確定要刪除嗎？')) {
      try {
        await deleteById(`/api/ehrm/v1/ReferenceCheck`, id);
        fetchReferenceChecks(searchTerm, currentPage);
      } catch (error) {
        console.error('刪除失敗:', error);
      }
    }
  };

  // 計算總頁數
  const totalPages = Math.ceil(totalReferenceChecks / referenceChecksPerPage);

  // 改變頁面
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  // 分頁按鈕渲染邏輯
  const renderPaginationButtons = () => {
    const maxButtons = 5;
    let startPage, endPage;

    if (totalPages <= maxButtons) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= Math.ceil(maxButtons / 2)) {
        startPage = 1;
        endPage = maxButtons;
      } else if (currentPage + Math.floor(maxButtons / 2) >= totalPages) {
        startPage = totalPages - maxButtons + 1;
        endPage = totalPages;
      } else {
        startPage = currentPage - Math.floor(maxButtons / 2);
        endPage = currentPage + Math.floor(maxButtons / 2);
      }
    }

    return (
      <>
        {startPage > 1 && (
          <>
            <button onClick={() => paginate(1)} className="mx-1 px-3 py-1 border rounded">1</button>
            {startPage > 2 && <span className="mx-1">...</span>}
          </>
        )}
        {Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i).map(number => (
          <button
            key={number}
            onClick={() => paginate(number)}
            className={`mx-1 px-3 py-1 border rounded ${currentPage === number ? 'bg-blue-500 text-white' : 'bg-white'}`}
          >
            {number}
          </button>
        ))}
        {endPage < totalPages && (
          <>
            {endPage < totalPages - 1 && <span className="mx-1">...</span>}
            <button onClick={() => paginate(totalPages)} className="mx-1 px-3 py-1 border rounded">{totalPages}</button>
          </>
        )}
      </>
    );
  };


  if (loading) return <div>加載中...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="mx-auto">
      <div className="flex justify-between items-center mb-6">
        <div>
          <span className="text-gray-400">面試管理</span>
          <h1 className="text-2xl font-semibold text-gray-800">資歷查核列表</h1>
        </div>
      </div>
      <div >
        <div className="text-right">
          <Link to="/interviews" className="mr-1 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300">
            返回面試
          </Link>
          <Link to={`/interviews/reference-checks/new?interviewId=${id}`} className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300">
            新增履歷查核
          </Link>
        </div>
        <div className="overflow-x-auto mt-3">
          <table className="w-full border-collapse">
            <thead>
              <tr className="bg-gray-100 text-left">
                <th className="p-3 font-semibold text-sm text-gray-600">公司</th>
                <th className="p-3 font-semibold text-sm text-gray-600">姓名</th>
                <th className="p-3 font-semibold text-sm text-gray-600">職稱</th>
                <th className="p-3 font-semibold text-sm text-gray-600">管理</th>
              </tr>
            </thead>
            <tbody>
              {referenceChecks.map((referenceCheck, index) => (
                <tr key={referenceCheck.id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                  <td className="p-3 text-sm text-gray-700">{referenceCheck.companyName}</td>
                  <td className="p-3 text-sm text-gray-700">{referenceCheck.name}</td>
                  <td className="p-3 text-sm text-gray-700">{referenceCheck.jobTitle}</td>
                  <td className="p-3 text-sm">
                    <button onClick={() => navigate(`/interviews/reference-checks/edit/${referenceCheck.id}?interviewId=${id}`)} className="px-2 py-2 rounded btn-icon mr-2">
                      <FiEdit2 />
                    </button>
                    <button onClick={() => handleDelete(referenceCheck.id)} className="px-2 py-2 rounded btn-icon">
                      <RiDeleteBinLine />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {totalPages > 1 && (
          <div className="mt-6 flex justify-center items-center">
            <button
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
              className="mx-1 px-3 py-1 border rounded text-gray-600 disabled:opacity-50 hover:bg-gray-100 transition duration-300"
            >
              ←
            </button>
            {renderPaginationButtons()}
            <button
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="mx-1 px-3 py-1 border rounded text-gray-600 disabled:opacity-50 hover:bg-gray-100 transition duration-300"
            >
              →
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default ReferenceChecks;
