import React, { useState } from 'react';
import { MdHeight } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

function ServiceAgreement() {
  const navigate = useNavigate();
  const [agree, setAgree] = useState(false);
  const [name, setName] = useState('王大明');
  return (
    <div className="bg-white shadow-sm rounded-lg p-6 max-w-screen-2xl mx-auto">
      <div>
        <div className="text-center font-semibold mb-4">服務同意書</div>
        <p>
          立同意書人 <span className='underline'>{name}</span> 今承貴公司同意聘僱，
        </p>
        <p>
          本人將秉持忠誠服務，願遵守服務同意書約定內容（視為勞動契約一部分）。
        </p>
        <p>
          一、立同意書人同意配合公司每年依人事行政局公告之政府行政機關辦公日曆表出勤。
        </p>
        <p>
          二、立同意書人已了解公司特休假規定如下，若離職年資未滿期者，願以事假返還預休之特休假，並簽署『預休特休返還同意書』。
          <ul>
            <li>
              1.本公司特休假為預先給予制
            </li>
            <li>
              2.新人當年度通過試用期滿考核，可享有3天特休假，任職未滿6個月離職者，則須以事假返還預休之特休時數，並簽署『預休特休返還同意書』。（6個月以內約聘除外）
            </li>
            <li>
              3.新人通過考核成為正式人員後，隔年1月1日公司將預先給予7天特休假，但若任職未滿一年離職者，則須以事假返還預休之特休時數，並簽署『預休特休返還同意書』。
            </li>
            <li>
              4.之後每年1月1日公司將預先給予當年滿期特休天數，但若任職未滿其年資者，則須以事假返還預休之特休時數，並簽署『預休特休返還同意書』。
            </li>
          </ul>
        </p>
        <p>
          三、立同意書人已了解員工教育訓練辦法規定如下，並願配合公司要求簽署服務契約書。
          <ul>
            <li>
              1.各部門主管指派或員工申請參加國內外教育訓練或原廠指定會議及各項活動，需於事前一週填寫『外訓、出差認證考試申請』並檢附相關資訊，以利人力資源管理中心協助預訂機票、簽證、住宿及投保旅遊平安險。
            </li>
            <li>
              2.凡員工參加國內付費課程、國外原廠會議、教育訓練、活動費用（包含課程費用、機票、簽證、住宿費用，若為原廠贊助，亦須列入費用中）、認證考試及技能檢定費用，累計金額達叁萬元(含)以上須簽訂服務契約書期限一年、累計達捌萬元(含)以上簽訂一年半。
            </li>
            <li>
              3.外訓人員需於課程結束一週內填寫『外訓心得報告書』，出差結束一週內需填寫『出差報告單』，經部門主管簽核送交人力資源管理中心存檔。
            </li>
          </ul>
        </p>
        <p>
          四、立同意書人已了解在職期間不得與他人討論薪資或外洩薪資機密，若因個人行為導致公司賠償或損失，本人同意負連帶法律責任。
        </p>
        <p>
          五、立同意書人已了解在職期間應遵守公司規範，若因個人行為導致公司賠償或損失，本人同意依員工獎懲辦法辦理，離職後亦願負連帶法律責任。
        </p>
        <p>
        以上條文內容及相關約定事項，立同意書人完全明瞭且同意遵守，特立此書為憑。
        </p>
        <div className='mt-4 mb-4'>
          <input type="checkbox" className='mr-2' onClick={() => setAgree(!agree)}/>
          我已閱讀並同意遵守本服務同意書的所有條款和條件。
        </div>
        <div>
          此  致
          <br/>
          零壹科技股份有限公司
        </div>
      </div>
      <div className="text-right mt-4">
        <button
          onClick={() => navigate('/recruiter')}
          className="mr-2 px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        >
          確認
        </button>
        <button
          onClick={() => navigate('/recruiter')}
          className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        >
          返回
        </button>
      </div>
    </div>
  );
}

export default ServiceAgreement;
