import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function PhysicalHealthDataCollectionAgreement() {
  const navigate = useNavigate();
  const [agree, setAgree] = useState(false);
  const [name, setName] = useState('王大明');
  return (
    <div className="bg-white shadow-sm rounded-lg p-6 max-w-screen-2xl mx-auto">
      <div>
        <div className="text-center font-semibold mb-4">體（健）檢資料蒐集同意書</div>
        <p>
        為符合「勞工安全衛生法」及「勞工健康保護規則」，本人同意提供體（健）檢報告，供勞工健康管理業務之用。			
        <br/>
        已確認受檢單位為勞工體格及健康檢查認可醫療機構療機構			
        <br/>
        認可機構查詢:https://hrpts.osha.gov.tw/asshp/hrpm1055.aspx			
        <br/>
        已確認報告之檢查期限符合規定，檢查日期：報到前一年內			
        <br/>
        （未滿40歲者，每5年1次；年滿40歲未滿65歲者，每3年1次；年滿65歲以上者，每年1次）			
        <br/>
        已確認項目完整，新進員工（體格檢查項目）或在職員工（健康檢查項目）			
        <br/>
        受檢項目請參照以下說明（勞工健康保護規則附表八）			
        </p>

        
        <table>
          <thead>
            <tr>
              <th colSpan={2}>附表八 一般體格檢查、健康檢查項目表</th>
            </tr>
            <tr>
              <th>體格檢查項目</th>
              <th>健康檢查項目</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>(1) 作業經歷、既往病史、生活習慣及自覺症狀之調查。</td>
              <td>(1) 作業經歷、既往病史、生活習慣及自覺症狀之調查。</td>
            </tr>
            <tr>
              <td>(2) "身高、體重、腰圍、視力、辨色力、聽力、血壓與身體各系統或部位之身體檢查及問診。"</td>
              <td>(2) "身高、體重、腰圍、視力、辨色力、聽力、血壓與身體各系統或部位之身體檢查及問診。"</td>
            </tr>
            <tr>
              <td>(3) 胸部Ｘ光（大片）攝影檢查。</td>
              <td>(3) 胸部Ｘ光（大片）攝影檢查。</td>
            </tr>
            <tr>
              <td>(4) 尿蛋白及尿潛血之檢查。</td>
              <td>(4) 尿蛋白及尿潛血之檢查。</td>
            </tr>
            <tr>
              <td>(5) 血色素及白血球數檢查。</td>
              <td>(5) 血色素及白血球數檢查。</td>
            </tr>
            <tr>
              <td>(6) "血糖、血清丙胺酸轉胺酶(ALT)、肌酸酐(creatinine)、膽固醇、三酸甘油酯、高密度脂蛋白膽固醇之檢查。"</td>
              <td>(6) "血糖、血清丙胺酸轉胺酶(ALT)、肌酸酐(creatinine)、膽固醇、三酸甘油酯、高密度脂蛋白膽固醇、低密度脂蛋白膽固醇之檢查。"</td>
            </tr>
            <tr>
              <td>(7) 其他經中央主管機關指定之檢查。</td>
              <td>(7) 其他經中央主管機關指定之檢查。</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="text-right mt-4">
        <button
          onClick={() => navigate('/recruiter')}
          className="mr-2 px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        >
          確認
        </button>
        <button
          onClick={() => navigate('/recruiter')}
          className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        >
          返回
        </button>
      </div>
    </div>
  );
}

export default PhysicalHealthDataCollectionAgreement;
