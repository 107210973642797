import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const get = async (entpoint, params = {}) => {
  try {
    const response = await api.get(entpoint, { params });
    return response.data.items;
  } catch (error) {
    console.error('獲取失敗:', error);
    throw error;
  }
};

export const getById = async (entpoint, id) => {
  try {
    const response = await api.get(entpoint + '/' + id);
    return response.data.item;
  } catch (error) {
    console.error('獲取失敗:', error);
    throw error;
  }
};

export const post = async (entpoint, data) => {
  try {
    const response = await api.post(entpoint, data);
    return response.data;
  } catch (error) {
    console.error('創建失敗:', error);
    throw error;
  }
};

export const putById = async (entpoint, id, data) => {
  try {
    const response = await api.put(`${entpoint}/${id}`, data);
    return response.data;
  } catch (error) {
    console.error('更新失敗:', error);
    throw error;
  }
};

export const deleteById = async (entpoint, id) => {
  try {
    const response = await api.delete(`${entpoint}/${id}`);
    return response.data;
  } catch (error) {
    console.error('刪除失敗:', error);
    throw error;
  }
};

export const getCompanies = async () => {
  try {
    const response = await api.get(`/api/comm/v1/system/Corporations`);
    return response.data.items;
  } catch (error) {
    console.error('獲取公司詳情失敗:', error);
    throw error;
  }
};

export const getCompanyById = async (id) => {
  try {
    const response = await api.get(`/api/comm/v1/system/Corporation/${id}`);
    return response.data.item;
  } catch (error) {
    console.error('獲取公司詳情失敗:', error);
    throw error;
  }
};

export const createCompany = async (companyData) => {
  try {
    const response = await api.post('/api/comm/v1/system/Corporations', companyData);
    return response.data;
  } catch (error) {
    console.error('創建公司失敗:', error);
    throw error;
  }
};

export const updateCompany = async (id, companyData) => {
  try {
    const response = await api.put(`/api/comm/v1/system/Corporation/${id}`, companyData);
    return response.data;
  } catch (error) {
    console.error('更新公司失敗:', error);
    throw error;
  }
};

export const getEvaluationcriteria = async (category, positionId) => {
  try {
    const response = await api.get(`/api/interview/v1/evaluationcriteria/category/${category}/Position/${positionId}`);
    return response.data;
  } catch (error) {
    console.error('獲取評估標準失敗:', error);
    throw error;
  }
};

export const updateEvaluationcriteria = async (id, evaluationcriteriaData) => {
  try {
    const response = await api.put(`/api/interview/v1/evaluationcriteria/${id}`, evaluationcriteriaData);
    return response.data;
  } catch (error) {
    console.error('更新評估標準失敗:', error);
    throw error;
  }
};

export const createEvaluationcriteria = async (evaluationcriteriaData) => {
  try {
    const response = await api.post('/api/interview/v1/evaluationcriteria', evaluationcriteriaData);
    return response.data;
  } catch (error) {
    console.error('創建評估標準失敗:', error);
    throw error;
  }
};

export const deleteEvaluationcriteria = async (id) => {
  try {
    const response = await api.delete(`/api/interview/v1/evaluationcriteria/${id}`);
    return response.data;
  } catch (error) {
    console.error('刪除評估標準失敗:', error);
    throw error;
  }
};

//========
// 面試來源
//========

export const getApplicantSources = async () => {
  try {
    const response = await api.get('/api/ehrm/v1/ApplicantSources');
    return response.data;
  } catch (error) {
    console.error('獲取面試來源失敗:', error);
    throw error;
  }
};

export const createApplicantSource = async (applicantSourceData) => {
  try {
    const response = await api.post('/api/ehrm/v1/ApplicantSource', applicantSourceData);
    return response.data;
  } catch (error) {
    console.error('創建面試來源失敗:', error);
    throw error;
  }
};

export const updateApplicantSource = async (id, applicantSourceData) => {
  try {
    const response = await api.put(`/api/ehrm/v1/ApplicantSource/${id}`, applicantSourceData);
    return response.data;
  } catch (error) {
    console.error('更新面試來源失敗:', error);
    throw error;
  }
};

export const deleteApplicantSource = async (id) => {
  try {
    const response = await api.delete(`/api/ehrm/v1/ApplicantSource/${id}`);
    return response.data;
  } catch (error) {
    console.error('刪除面試來源失敗:', error);
    throw error;
  }
};

export default api;
