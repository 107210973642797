import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getCompanyById, createCompany, updateCompany } from '../../services/api';
import { FiEdit2 } from 'react-icons/fi';
import { RiDeleteBinLine } from "react-icons/ri";

function RoleForm() {
  const [formData, setFormData] = useState({
    roleName: '',
  });
  const [roles, setRoles] = useState([
      { id: 1, roleName: '管理者' },
      { id: 2, roleName: '單位主管' },
      { id: 3, roleName: '一般員工' },
  ]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const [editingIndex, setEditingIndex] = useState(-1);

  useEffect(() => {
    if (id) {
      // fetchCompanyData();
    }
  }, [id]);

  const fetchCompanyData = async () => {
    setLoading(true);
    try {
      const data = await getCompanyById(id);
      setFormData(data);
    } catch (error) {
      setError('獲取公司數據失敗');
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      if (id) {
        await updateCompany(id, formData);
      } else {
        await createCompany(formData);
      }
      navigate('/companies');
    } catch (error) {
      setError('保存角色數據失敗');
    } finally {
      setLoading(false);
    }
  };

  const handleAddRole = () => {
    if (formData.roleName) {
      setRoles([...roles, { id: roles.length + 1, roleName: formData.roleName }]);
      setFormData({ ...formData, roleName: '' });
    }
  };

  const handleRoleChange = (index, field, value) => {
    const updatedRoles = roles.map((role, i) => 
      i === index ? { ...role, [field]: value } : role
    );
    setRoles(updatedRoles);
  };

  const handleEditRole = (index) => {
    setEditingIndex(index);
  };

  const handleSaveRole = () => {
    setEditingIndex(-1);
  };

  const handleDeleteRole = (index) => {
    if (window.confirm('確定要刪除嗎？')) {
      try {
        const updatedRoles = roles.filter((_, i) => i !== index);
        setRoles(updatedRoles);
      } catch (error) {
        console.error('刪除角色失敗:', error);
      }
    }
  };

  const handleCancelRole = () => {
    setEditingIndex(-1);
  };

  // 添加處理銀行選擇的函數
  const handleBankSelect = (selectedBank) => {
    setFormData({ ...formData, bank: selectedBank });
  };

  if (loading) return <div>加載中...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="mx-auto">
      <div className="flex justify-between items-center mb-6">
        <div>
          <span className="text-gray-400">系統管理</span>
          <h1 className="text-2xl font-semibold text-gray-800">角色編輯</h1>
        </div>
      </div>
      <div>
        <form onSubmit={handleSubmit}>
          <div>
            <div>
              <table className="w-full border-collapse border border-gray-300">
                <thead className="bg-gray-50">
                  <tr className="text-center">
                    <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider">角色名稱</th>
                    <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider">管理</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="p-2">
                      <input
                        type="text"
                        name="roleName"
                        value={formData.roleName}
                        onChange={handleInputChange}
                        className="w-full px-2 py-1 border border-gray-300 rounded-md"
                        placeholder="角色名稱"
                      />
                    </td>
                    <td className="p-2 text-center">
                      <button
                        type="button"
                        onClick={handleAddRole}
                        className="px-3 py-2 rounded-md btn-new hover:btn-new"
                      >
                        新增
                      </button>
                    </td>
                  </tr>
                  {roles.map((role, index) => (
                    <tr key={index} className="border-b border-gray-200">
                      <td className="pl-4">
                        {editingIndex === index ? (
                          <input
                            type="text"
                            value={role.roleName}
                            onChange={(e) => handleRoleChange(index, 'roleName', e.target.value)}
                            className="w-full px-2 py-1 border border-gray-300 rounded-md"
                          />
                        ) : (
                          role.roleName
                        )}
                      </td>
                      <td className="p-2 text-center">
                        <button
                          type="button"
                          onClick={() => navigate(`/roles/edit/${role.id}`)}
                          className="px-2 py-2 rounded-md btn-icon"
                        >
                          <FiEdit2 />
                        </button>
                        <button
                          type="button"
                          onClick={() => handleDeleteRole(index)}
                          className="ml-2 px-2 py-2 rounded-md btn-icon"
                        >
                          <RiDeleteBinLine />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default RoleForm;
