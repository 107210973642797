import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getById } from '../../services/api';
function ReferenceCheckForm() {
  const [formData, setFormData] = useState({});
  const [newLocation, setNewLocation] = useState({ city: '', address: '' });
  const [editingIndex, setEditingIndex] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const interviewId = new URLSearchParams(window.location.search).get('interviewId');
  const id = useParams().id;
  console.log('interviewId', interviewId);
  console.log('id', id);
  // 添加銀行列表狀態
  const [banks] = useState([
    '台灣銀行', '中國信託銀行', '國泰世華銀行', '玉山銀行', '台北富邦銀行'
  ]);

  useEffect(() => {
    if (id) {
      fetchReferenceCheckData();
    }
  }, [id]);

  const fetchReferenceCheckData = async () => {
    setLoading(true);
    try {
      const data = await getById(`/api/ehrm/v1/ReferenceCheck`, id);
      console.log('data', data);
      setFormData(data);
    } catch (error) {
      setError('獲取公司數據失敗');
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleNewLocationChange = (e) => {
    const { name, value } = e.target;
    setNewLocation(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      // if (id) {
      //   await updateReferenceCheck(id, formData);
      // } else {
      //   await createReferenceCheck(formData);
      // }
      navigate('/interviews/reference-checks');
    } catch (error) {
      setError('保存公司數據失敗');
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div>加載中...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="mx-auto">
      <div className="flex justify-between items-center mb-6">
        <div>
          <h1 className="text-2xl font-semibold text-gray-800">資歷查核</h1>
        </div>
      </div>
      <div className="bg-white shadow-sm rounded-lg p-6 max-w-6xl">
        <form onSubmit={handleSubmit} className="space-y-6">
          <h3 className="text-lg font-semibold text-gray-800 mb-4">應徵者</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">姓名</label>
              {formData.name}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">應徵職務</label>
              {formData.candidateJobTitle}
            </div>
          </div>
          <h3 className="text-lg font-semibold text-gray-800 mb-4">聯絡人</h3>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
            <div className="col-span-1">
              <label className="block text-sm font-medium text-gray-700 mb-1">姓名</label>
              <input
                type="text"
                name="name"
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                value={formData.name}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">電話</label>
              <input
                type="text"
                name="phone"
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                value={formData.phone}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">公司</label>
              <input
                type="text"
                name="companyName"
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                value={formData.companyName}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">職稱</label>
              <input
                type="text"
                name="jobTitle"
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                value={formData.jobTitle}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">與應徵者關係</label>
            <div className="flex flex-row space-x-4">
              <label className="inline-flex items-center space-x-2">
                <input
                  type="radio"
                  name="relationship"
                  value="主管"
                  className="form-radio"
                  checked={formData.relationship === '主管'}
                  onChange={handleInputChange}
                />
                <span>主管</span>
              </label>
              <label className="inline-flex items-center space-x-2">
                <input
                  type="radio"
                  name="relationship"
                  value="同事"
                  className="form-radio"
                  checked={formData.relationship === '同事'}
                  onChange={handleInputChange}
                />
                <span>同事</span>
              </label>
              <label className="inline-flex items-center space-x-2">
                <input
                  type="radio"
                  name="relationship"
                  value="其他"
                  className="form-radio"
                  checked={formData.relationship === '其他'}
                  onChange={handleInputChange}
                />
                <span>其他，請註明：</span>
              </label>
              <div>
                <input
                  type="text"
                  name="address"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  value={formData.address}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
          <h3 className="text-lg font-semibold text-gray-800 mb-4">查證事項</h3>
          <div>
            1.擔任貴公司
            <input
              type="text"
              name="endYear"
              className="w-16 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              value={formData.endYear}
              onChange={handleInputChange}
            />
            一職，在公司服務的工作時間自（
            <input
              type="text"
              name="startYear"
              className="w-16 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              value={formData.startYear}
              onChange={handleInputChange}
            />
            <span>/</span>
            <input
              type="text"
              name="startMonth"
              className="w-16 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              value={formData.startMonth}
              onChange={handleInputChange}
            />
            <label className="inline-flex items-center space-x-2">到</label>
            <input
              type="text"
              name="endYear"
              className="w-16 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              value={formData.endYear}
              onChange={handleInputChange}
            />
            <span>/</span>
            <input
              type="text"
              name="endMonth"
              className="w-16 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              value={formData.endMonth}
              onChange={handleInputChange}
            />
            ）
          </div>
          <div>
            2.請問他於貴公司任職時是否擔任主管？
            <input
              type="radio"
              name="manager"
              value="Y"
              className="form-radio"
              checked={formData.manager === 'Y'}
              onChange={handleInputChange}
            />
            是 
            <input
              type="radio"
              name="manager"
              value="N"
              className="form-radio"
              checked={formData.manager === 'N'}
              onChange={handleInputChange}
            />
            否 ；管理人數約
            <input
              type="text"
              name="endMonth"
              className="w-16 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              value={formData.endMonth}
              onChange={handleInputChange}
            />
            人
          </div>
          <div>
            3.請問他在公司是負責什麼工作內容？他的工作表現如何？工作配合度如何？工作上是否有需要加強的地方？
            <textarea
              name="workContent"
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              value={formData.workContent}
              onChange={handleInputChange}
            />
          </div>
          <div>
            4.請問他是否會主動提出想法或建議？當意見不同時，他會如何處理?是否可以溝通或討論？
            <textarea
              name="workContent"
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              value={formData.workContent}
              onChange={handleInputChange}
            />
          </div>
          <div>
            5.在他任職期間是否會常請假、遲到、早退，不配合公司規定出勤？是否曾有不良行為 (跟同事借錢, 被公司懲處)？
            <textarea
              name="workContent"
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              value={formData.workContent}
              onChange={handleInputChange}
            />
          </div>
          <div>
            6.請問他的個性及情緒管理如何？是否可以跟不同類型的人共事？是否有衝突產生？善於團隊合作嗎？
            <textarea
              name="workContent"
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              value={formData.workContent}
              onChange={handleInputChange}
            />
          </div>
          <div>
            7.是否可以針對以下項目以十分量表評分
            <div>
              <table className="w-full">
                <thead>
                  <tr className="text-center">
                    <th className="w-1/5">項目</th>
                    <th className="w-1/5">評分0~10分</th>
                    <th className="w-3/5">補充說明</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>(1)目標達成率(業務)</td>
                    <td className="text-center">
                      <input
                        type="number"
                        name="targetAchievementRate"
                        min="0"
                        max="10"
                        className="w-24 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        value={formData.targetAchievementRate}
                        onChange={handleInputChange}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="targetAchievementRateComment"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        value={formData.targetAchievementRateComment}
                        onChange={handleInputChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>(2)積極度</td>
                    <td className="text-center">
                      <input
                        type="number"
                        name="positivity"
                        min="0"
                        max="10"
                        className="w-24 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        value={formData.positivity}
                        onChange={handleInputChange}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="positivityComment"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        value={formData.positivityComment}
                        onChange={handleInputChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>(3)溝通協調能力</td>
                    <td className="text-center">
                      <input
                        type="number"
                        name="communicationCoordination"
                        min="0"
                        max="10"
                        className="w-24 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        value={formData.communicationCoordination}
                        onChange={handleInputChange}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="communicationCoordinationComment"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        value={formData.communicationCoordinationComment}
                        onChange={handleInputChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>(4)邏輯條理性</td>
                    <td className="text-center">
                      <input
                        type="number"
                        name="logicalThinking"
                        min="0"
                        max="10"
                        className="w-24 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        value={formData.logicalThinking}
                        onChange={handleInputChange}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="logicalThinkingComment"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        value={formData.logicalThinkingComment}
                        onChange={handleInputChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>(5)細心度</td>
                    <td className="text-center">
                      <input
                        type="number"
                        name="attentionToDetail"
                        min="0"
                        max="10"
                        className="w-24 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        value={formData.attentionToDetail}
                        onChange={handleInputChange}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="attentionToDetailComment"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        value={formData.attentionToDetailComment}
                        onChange={handleInputChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>(6)抗壓力</td>
                    <td className="text-center">
                      <input
                        type="number"
                        name="stressResistance"
                        min="0"
                        max="10"
                        className="w-24 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        value={formData.stressResistance}
                        onChange={handleInputChange}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="stressResistanceComment"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        value={formData.stressResistanceComment}
                        onChange={handleInputChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>(7)問題解決力</td>
                    <td className="text-center">
                      <input
                        type="number"
                        name="problemSolving"
                        min="0"
                        max="10"
                        className="w-24 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        value={formData.problemSolving}
                        onChange={handleInputChange}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="problemSolvingComment"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        value={formData.problemSolvingComment}
                        onChange={handleInputChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>(8)學習能力</td>
                    <td className="text-center">
                      <input
                        type="number"
                        name="learningAbility"
                        min="0"
                        max="10"
                        className="w-24 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        value={formData.learningAbility}
                        onChange={handleInputChange}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="learningAbilityComment"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        value={formData.learningAbilityComment}
                        onChange={handleInputChange}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="mt-4">
              8.請問您知道他離職的原因？
              <textarea
                name="workContent"
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                value={formData.workContent}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="mt-4">
            9. 你是否願意再找他回去工作？
            <div>
              <input
                type="radio"
                name="rehire"
                value="NC"
                className="form-radio mr-1"
              />
              不表態
              <input
                type="radio"
                name="rehire"
                value="Y"
                className="form-radio mr-1 ml-3"
              />
              是
              <input
                type="radio"
                name="rehire"
                value="N"
                className="form-radio mr-1 ml-3"
              />
              否，請註明原因：
              <input
                type="text"
                name="rehireReason"
                className="px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                value={formData.rehireReason}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div>
            備註：
            <textarea
              name="rehireReason"
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              value={formData.rehireReason}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <h3 className="text-lg font-semibold text-gray-800 mb-2">傳送訊息對象</h3>
            <div>
              ■ 初試主管：王大頭
            </div>
            <div>
            □ 複試主管：陳明明
            </div>
          </div>
          <div className="flex justify-center space-x-3">
            <button type="button" onClick={() => navigate(`/interviews/reference-checks/${id}`)} className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-100 transition duration-300">
              返回
            </button>
            <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300">
              儲存
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ReferenceCheckForm;
