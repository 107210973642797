import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

function InterviewResult() {
  const [interviewResult, setInterviewResult] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const fetchInterviewResult = useCallback(async (search, page) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${API_URL}/api/comm/v1/system/Corporations`, {
        // params: {
        //   page: page,
        //   limit: companiesPerPage,
        //   search: search
        // }
      });
      console.log('API 完整響應:', response.data);

      if (response.data && Array.isArray(response.data.items)) {
        setInterviewResult(response.data.items);
      } else {
        console.error('API 響應結構不符合預期:', response.data);
        setInterviewResult([]);
        setError('獲取數據時出現問題');
      }
    } catch (error) {
      console.error('獲取失敗:', error);
      setError('獲取列表時發生錯誤');
      setInterviewResult([]);
    } finally {
      setLoading(false);
    }
  });

  return (
    <div className="mx-auto">
      <div className="flex justify-between items-center mb-6">
        <div>
          <span className="text-gray-400">面試管理</span>
          <h1 className="text-2xl font-semibold text-gray-800">面談結果</h1>
        </div>
      </div>
      <div >
        <div className='flex w-3/4'>
          <div className='flex-1'>
            <label className="text-gray-400">應徵人員姓名</label>
            <div>王小明</div>
          </div>
          <div className='flex-1'>
            <label className="text-gray-400">應徵公司</label>
            <div>零壹科技</div>
          </div>
          <div className='flex-1'>
            <label className="text-gray-400">應徵部門</label>
            <div>資訊中心</div>
          </div>
          <div className='flex-1'>
            <label className="text-gray-400">應徵職務</label>
            <div>工程師</div>
          </div>
        </div>
        <div className="flex mt-4">
          <div className="overflow-x-auto w-1/2">
            <h3>評價級別-HR</h3>
            <table className="w-full border-collapse">
              <thead>
                <tr className="bg-gray-100 text-left">
                  <th className="p-3 font-semibold text-sm text-gray-600">評分項目</th>
                  <th className="p-3 font-semibold text-sm text-gray-600">評分</th>
                </tr>
              </thead>
              <tbody>
                {interviewResult.map((company, index) => (
                  <tr key={company.id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                    <td className="p-3 text-sm text-gray-700">{company.taxIdNumber}</td>
                    <td className="p-3 text-sm text-gray-700">{company.chineseName}</td>
                    <td className="p-3 text-sm text-gray-700">{company.bank}</td>
                    <td className="p-3 text-sm">
                      <button onClick={() => navigate(`/companies/edit/${company.id}`)} className="bg-blue-500 text-white px-3 py-1 rounded mr-2 hover:bg-blue-600 transition duration-300">
                        編輯
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className='mt-4'>
              評語xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
            </div>
            <div>
              複試主管錄取於否：錄取
            </div>
          </div>
          <div className="overflow-x-auto w-1/2 ml-4">
            <h3>評價級別-主管</h3>
            <table className="w-full border-collapse">
              <thead>
                <tr className="bg-gray-100 text-left">
                  <th className="p-3 font-semibold text-sm text-gray-600">評項項目</th>
                  <th className="p-3 font-semibold text-sm text-gray-600">評分</th>
                </tr>
              </thead>
              <tbody>
                {interviewResult.map((company, index) => (
                  <tr key={company.id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                    <td className="p-3 text-sm text-gray-700">{company.taxIdNumber}</td>
                    <td className="p-3 text-sm text-gray-700">{company.chineseName}</td>
                    <td className="p-3 text-sm text-gray-700">{company.bank}</td>
                    <td className="p-3 text-sm">
                      <button onClick={() => navigate(`/companies/edit/${company.id}`)} className="bg-blue-500 text-white px-3 py-1 rounded mr-2 hover:bg-blue-600 transition duration-300">
                        編輯
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className='mt-4'>
              初試評語xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
            </div>
            <div>
              複試評語xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
            </div>
          </div>
        </div>
        <div className="mt-2">
          <div>
            <input type="radio" id="accept" name="status" value="accept" className='mr-1' />
            <label htmlFor="accept">錄取</label> 預計上班日 <input type="date" id="startDay" name="startDay" />
          </div>
          <div>
            <input type="radio" id="backup" name="status" value="backup" className='mr-1' />
            <label htmlFor="backup">備選</label>
          </div>
          <div>
            <input type="radio" id="recommendDepartment" name="status" value="recommendDepartment" className='mr-1' />
            <label htmlFor="recommendDepartment">推薦部門</label>
            公司
            <select id="company" name="company" className='mr-8'>
              <option value="company1">公司1</option>
              <option value="company2">公司2</option>
            </select>
            部門
            <select id="department" name="department" className='mr-8'>
              <option value="department1">部門1</option>
              <option value="department2">部門2</option>
            </select>
            應徵職務
            <select id="position" name="position" className='mr-8'>
              <option value="position1">應徵職務1</option>
              <option value="position2">應徵職務2</option>
            </select>
            職務類別
            <select id="jobType" name="jobType">
              <option value="jobType1">職務類別1</option>
              <option value="jobType2">職務類別2</option>
            </select>
          </div>
          <div>
            <input type="radio" id="notSuitable" name="status" value="notSuitable" className='mr-1' />
            <label htmlFor="notSuitable">不適合，寄送感謝函</label>
          </div>
        </div>
        <div className="flex justify-center space-x-3 mt-3">
        <button type="button" onClick={() => navigate('/interviews')} className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-100 transition duration-300">
          返回
        </button>
        <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300">
          送出
        </button>
      </div>
      </div>
    </div>
  );
}

export default InterviewResult;
