import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

function EmployeeComputerSoftwareUseSelfRegulationAgreement() {
  const navigate = useNavigate();
  const [agree, setAgree] = useState(false);
  const [name, setName] = useState('王大明');
  return (
    <div className="bg-white shadow-sm rounded-lg p-6 max-w-screen-2xl mx-auto">
      <div>
        <div className="text-center font-semibold mb-4">員工電腦軟硬體使用自律同意書</div>
        <p>
        立同意書人 <span className='underline'>{name}</span> 茲保證於公司任職期間絕對遵守下列規範事項，如有違反任一事項願接受公司相關規定之處罰：	
        <br/>
        1	經由公司採購流程所取得之合法軟體，始可於公司內部使用 。
        <br/>
        2	除公務使用外及未經公司部主管級以上主管同意，不可複製公司內部之軟體、文件資料至公司以外之場所使用或販賣。
        <br/>
        3	軟體之重製應以符合該軟體授權合約書之條約內容為基準，且需單位主管同意；不得為任何合約書規定以外的拷貝、複製行為。
        <br/>
        4	不可複製非經公司採購的非法軟體（含共享軟體）到公司安裝使用。若為試軟體應經單位主管同意始可使用。超過測試(試用)期間應予移除。
        <br/>
        5	應確定電腦內軟體具有合法版權後再行使用，以維護個人的權益；如發現司內有任何不法使用之軟體及相關文件，應即告知稽核單位，並由各單位資訊幕僚或資訊處協助處理。
        <br/>
        6	任何私人可攜式媒體(NB、隨身碟、SD卡 ..)上的程式或資料，造成公司內部電腦設備有所損害或中毒或破壞電腦資料，則需負擔起賠償責任。
        <br/>
        7	個人因職務所獲得公司內部系統的帳號、密碼（如AD、ERP系統、E-mail帳號 … 等）皆屬公司資產應妥善保管，不得公開（或與其他人共用）。
        <br/>
        8	不試圖入侵（hacking）公司任何電腦系統（個人有意或無意使用他人帳號、密碼亦屬入侵行為）。
        <br/>
        9	自攜電腦或其它私有裝置，需經部門主管同意後，填寫『員工電腦軟體使用自律同意書』 並遵守『無線網路存取管理辦法規範實施』。
        <br/>
        10	立書人充分瞭解員工在資訊軟硬體使用方面的上述規范，若違反約定，願自負民事及刑事責任，並賠償公司因此遭受之損失。
        <br/>
        11	請詳閱
        <Link to="/recruiter/information-security-promotion-instructions" className='underline text-blue-500'>
          附件一“資訊安全宣導須知”
        </Link>
        ，請員工了解內容並遵循之。
        </p>
        <div className='mt-4 mb-4'>
          <input type="checkbox" className='mr-2' onClick={() => setAgree(!agree)}/>
          我已閱讀並同意遵守本員工電腦軟硬體使用自律同意書的所有條款和條件。
        </div>
        <p>
          另有
          <Link to="/recruiter/company-authorized-software-list" className='underline text-blue-500'>
            附件二 “公司合法授權軟體清單”
          </Link>
          可供員工參考。
        </p>
      </div>
      <div className="text-right mt-4">
        <button
          onClick={() => navigate('/recruiter')}
          className="btn-save hover:btn-save text-white mr-2 px-3 py-1 rounded-md"
        >
          確認
        </button>
        <button
          onClick={() => navigate('/recruiter')}
          className="btn-return hover:btn-return-hover px-3 py-1 rounded-md"
        >
          返回
        </button>
      </div>
    </div>
  );
}

export default EmployeeComputerSoftwareUseSelfRegulationAgreement;
