import React from 'react';
import { useNavigate } from 'react-router-dom';

function Profile() {
  const data = {
    photo: '',
    chineseName: '王小明',
    englishName: 'Xiao Ming Wang',
    taxIdNumber: 'A123456789',
    nationality: '中華民國',
    birthday: '1990-01-01',
    gender: '男',
    bloodType: 'O',
    maritalStatus: '已婚',
    permanentAddress: '台北市大安區',
    residenceAddress: '台北市大安區',
    email: 'xmwang@example.com',
    telephone: '02-12345678',
    telephone2: '0912345678',
    mobile: '0912345678',
    military: '免役',
    military_retirement_date: '2024-01-01',
    disability: '是',
    disability_other: '極重度',
    salary: '50,000 / 600,000',
    available_date: '2024-01-01',
  };
  const navigate = useNavigate();

  return (
    <div id="application-info" className="max-w-screen-2xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <div>
          <span className="text-gray-400">應徵者輸入應徵資料</span>
          <h1 className="text-2xl font-semibold text-gray-800">基本資料</h1>
        </div>
      </div>
      <div className="bg-white shadow-sm rounded-lg p-6 max-w-6xl">
        <div className="overflow-x-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="col-span-2">
              <label className="block text-sm font-medium text-gray-700 mb-1">照片上傳</label>
              {data.photo && <img src={data.photo} alt="上傳的照片" className="mt-2 w-24 h-24 object-cover rounded-full" />}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">中文姓名</label>
              {data.chineseName}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">英文姓名</label>
              {data.englishName}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">身分證字號/居留證</label>
              {data.taxIdNumber}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">國籍</label>
              {data.nationality}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">生日</label>
              {data.birthday}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">性別</label>
              {data.gender}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">血型</label>
              {data.bloodType}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">婚姻狀況</label>
              {data.maritalStatus}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">戶籍地址</label>
              {data.permanentAddress}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">通訊地址</label>
              {data.residenceAddress}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">E-mail Address</label>
              {data.email}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">聯絡電話</label>
              {data.telephone}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">聯絡電話</label>
              {data.telephone2}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">行動電話</label>
              {data.mobile}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">兵役狀況</label>
              {data.military}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">退役日期</label>
              {data.military_retirement_date}
            </div>
            <div className='col-span-2'>
              <label className="block text-sm font-medium text-gray-700 mb-1">是否領有身心障礙者證明手冊？</label>
              {data.disability}
              （
              {data.disability_other}
              ）
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">希望待遇（月薪/年薪）</label>
              {data.salary}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">可報到日期</label>
              {data.available_date}
            </div>
          </div>
          <div className="flex justify-center space-x-3 mt-3">
            <button type="button" onClick={() => navigate(-1)}  className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-100 transition duration-300">
              返回
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
