import React, { useState, useEffect, useCallback } from 'react';
import { FaSortUp, FaSortDown } from 'react-icons/fa';
import { get, deleteById } from '../../services/api';
import Select from 'react-select'
import { FiEdit2 } from 'react-icons/fi';
import { RiDeleteBinLine } from "react-icons/ri";
import { FaRegSave } from "react-icons/fa";
import { AiOutlineRollback } from "react-icons/ai";

function Users() {
  const [formData, setFormData] = useState({
    id: '',
    userPrincipalName: '',
    roles: [],
    editUserPrincipalName: '',
    editRoles: [],
  });
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(25);
  const [totalUsers, setTotalUsers] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });
  const [editIndex, setEditIndex] = useState(null);

  const options = [
    { value: 'CEO', label: 'CEO' },
    { value: 'STOCK-HOLDER', label: 'STOCK-HOLDER' },
    { value: 'interviewee', label: 'interviewee' },
    { value: '管理者', label: '管理者' },
    { value: '單位主管', label: '單位主管' },
    { value: '一般員工', label: '一般員工' }
  ]

  const fetchUsers = useCallback(async (page) => {
    setLoading(true);
    setError(null);
    try {
      const response = await get('/api/comm/v1/Users');
      console.log('API 完整響應:', response);

      if (response && Array.isArray(response)) {
        setUsers(response);
        setTotalUsers(response.length);
      } else {
        console.error('API 響應結構不符合預期:', response);
        setUsers([]);
        setTotalUsers(0);
        setError('獲取公司數據時出現問題');
      }
    } catch (error) {
      console.error('獲取公司列表失敗:', error);
      setError('獲取公司列表時發生錯誤');
      setUsers([]);
      setTotalUsers(0);
    } finally {
      setLoading(false);
    }
  }, [usersPerPage]);

  useEffect(() => {
    fetchUsers(currentPage);
  }, [fetchUsers, currentPage]);

  const handleDelete = async (id) => {
    if (window.confirm('確定要刪除嗎？')) {
      try {
        await deleteById('/api/comm/v1/system/User', id);
        fetchUsers(currentPage);
      } catch (error) {
        console.error('刪除使用者失敗:', error);
      }
    }
  };

  const totalPages = Math.ceil(totalUsers / usersPerPage);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const renderPaginationButtons = () => {
    const maxButtons = 5;
    let startPage, endPage;

    if (totalPages <= maxButtons) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= Math.ceil(maxButtons / 2)) {
        startPage = 1;
        endPage = maxButtons;
      } else if (currentPage + Math.floor(maxButtons / 2) >= totalPages) {
        startPage = totalPages - maxButtons + 1;
        endPage = totalPages;
      } else {
        startPage = currentPage - Math.floor(maxButtons / 2);
        endPage = currentPage + Math.floor(maxButtons / 2);
      }
    }

    return (
      <>
        {startPage > 1 && (
          <>
            <button onClick={() => paginate(1)} className="mx-1 px-3 py-1 border rounded">1</button>
            {startPage > 2 && <span className="mx-1">...</span>}
          </>
        )}
        {Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i).map(number => (
          <button
            key={number}
            onClick={() => paginate(number)}
            className={`mx-1 px-3 py-1 border rounded ${currentPage === number ? 'bg-blue-500 text-white' : 'bg-white'}`}
          >
            {number}
          </button>
        ))}
        {endPage < totalPages && (
          <>
            {endPage < totalPages - 1 && <span className="mx-1">...</span>}
            <button onClick={() => paginate(totalPages)} className="mx-1 px-3 py-1 border rounded">{totalPages}</button>
          </>
        )}
      </>
    );
  };

  // 新增排序功能
  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key) {
      if (sortConfig.direction === 'asc') {
        direction = 'desc';
      } else if (sortConfig.direction === 'desc') {
        direction = ''; // 預設狀態
      }
    }
    setSortConfig({ key, direction });
  };

  // 排序使用者資料
  const sortedUsers = () => {
    if (sortConfig.key) {
      return [...users].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return users; // 如果沒有排序，返回原始資料
  };

  const handleInputChange = (e) => {
    const { target } = e;
    const { name, value } = target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSelectChange = (selectedOptions) => {
    setFormData(prevState => ({
      ...prevState,
      roles: selectedOptions.map(option => ({
        roleName: option.value
      }))
    }));
  };

  const handleEditSelectChange = (selectedOptions) => {
    setFormData(prevState => ({
      ...prevState,
      editRoles: selectedOptions.map(option => ({
        roleName: option.value
      }))
    }));
  };

  const handleEditInputChange = (e) => {
    const { target } = e;
    const { name, value } = target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleAdd = () => {
    if (formData.userPrincipalName && formData.roles) {
      setUsers([...users, formData]);
      setFormData({ userPrincipalName: '', roles: [] });
      // createUser(formData);
    }
  };

  const handleCancel = () => {
    setFormData({ userPrincipalName: '', roles: [] });
  };

  const handleEdit = (index) => {
    setFormData(users[index]);
    setEditIndex(index);
  };

  const handleSave = () => {
    console.log(formData);
    const updatedUsers = [...users];
    updatedUsers[editIndex] = formData;
    setUsers(updatedUsers);
    setEditIndex(null);
  };

  if (loading) return <div>加載中...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="mx-auto">
      <div className="flex justify-between items-center mb-6">
        <div>
          <span className="text-gray-400">系統管理</span>
          <h1 className="text-2xl font-semibold text-gray-800">使用者列表</h1>
        </div>
      </div>
      <div>
        <div>
          <table className="w-full border-collapse border border-gray-300">
            <thead className="bg-gray-50">
              <tr className="text-center select-none">
                <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider">
                  <div className="flex items-center justify-center" onClick={() => requestSort('userName')}>
                    {sortConfig.key === 'userName' && sortConfig.direction === 'asc' ? (
                      <FaSortUp className="mr-1" />
                    ) : sortConfig.key === 'userName' && sortConfig.direction === 'desc' ? (
                      <FaSortDown className="mr-1" />
                    ) : null} {/* 當為預設狀態時不顯示圖示 */}
                    帳號
                  </div>
                </th>
                <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider">
                  <div className="flex items-center justify-center" onClick={() => requestSort('roleName')}>
                    {sortConfig.key === 'roleName' && sortConfig.direction === 'asc' ? (
                      <FaSortUp className="mr-1" />
                    ) : sortConfig.key === 'roleName' && sortConfig.direction === 'desc' ? (
                      <FaSortDown className="mr-1" />
                    ) : null} {/* 當為預設狀態時不顯示圖示 */}
                    角色名稱
                  </div>
                </th>
                <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider">管理</th>
              </tr>
            </thead>
            <tbody>
            <tr>
                <td className="p-2">
                  <input
                    type="text"
                    name="userPrincipalName"
                    value={formData.userPrincipalName}
                    onChange={handleInputChange}
                    className="w-full px-2 py-1 border border-gray-300"
                    placeholder="帳號"
                    style={{ height: '38px', borderRadius: '4px', borderColor: '#e5e7eb !important' }}
                  />
                </td>
                <td className="p-2">
                  <Select
                    closeMenuOnSelect={false}
                    isMulti
                    onChange={handleSelectChange}
                    options={options}
                  />
                </td>
                <td className="p-2 text-center text-sm">
                  <button
                    type="button"
                    onClick={handleAdd}
                    className="px-3 py-2 rounded-md btn-new hover:btn-new"
                  >
                    新增
                  </button>
                </td>
              </tr>
              {sortedUsers().map((user, index) => (
                <tr key={user.id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                  <td className="p-3 text-sm text-gray-700">
                    {editIndex === index ? (
                      <input
                        type="text"
                        name="editUserPrincipalName"
                        value={formData.userPrincipalName}
                        onChange={handleEditInputChange}
                        className="w-full px-2 py-1 border border-gray-300 rounded-md"
                      />
                    ) : (
                      user.userPrincipalName
                    )}
                  </td>
                  <td className="p-3 text-sm text-gray-700">
                    {editIndex === index ? (
                      <Select
                        closeMenuOnSelect={false}
                        value={formData.roles.map(role => ({ value: role.roleName, label: role.roleName }))}
                        onChange={handleEditSelectChange}
                        isMulti
                        options={options}
                      />
                    ) : (
                      user.roles.map(role => role.roleName).join(', ')
                    )}
                  </td>
                  <td className="p-3 text-sm text-center">
                    {editIndex === index ? (
                      <>
                        <button onClick={handleSave} className="px-2 py-2 rounded-md btn-icon">
                          <FaRegSave />
                        </button>
                        <button onClick={() => setEditIndex(null)} className="ml-2 px-2 py-2 rounded-md btn-icon">
                          <AiOutlineRollback />
                        </button>
                      </>
                    ) : (
                      <>
                        <button onClick={() => handleEdit(index)} className="px-2 py-2 rounded-md btn-icon">
                          <FiEdit2 />
                        </button>
                        <button onClick={() => handleDelete(user.id)} className="ml-2 px-2 py-2 rounded-md btn-icon">
                          <RiDeleteBinLine />
                        </button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Users;
