import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { get } from '../../services/api';

const API_URL = process.env.REACT_APP_API_URL;

function Meetings() {
  const [meetings, setMeetings] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [meetingsPerPage] = useState(25);
  const [totalMeetings, setTotalMeetings] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { interviewId } = useParams();
  const [newFile, setNewFile] = useState({ fileType: '', upload: '' });
  const [editingIndex, setEditingIndex] = useState(-1);

  const fetchMeetings = useCallback(async (search, page) => {
    setLoading(true);
    setError(null);
    const meetings = await get('/api/comm/v1/system/Meetings');
    setMeetings(meetings);

    // setMeetings([{
    //   id: 'E57B270C-01A5-44D5-93FB-003D9DF9C66D',
    //   fileType: 'PPSS檔案',
    //   uploadFile: '王小明PPSS檔.pdf',
    // },{
    //   id: 'E57B270C-01A5-44D5-93FB-003D9DF9C66D',
    //   fileType: '薪資證明',
    //   uploadFile: '王小明薪資證明檔.docx',
    // }]);
    setTotalMeetings(0);  
    return;
    try {
      const response = await axios.get(`${API_URL}/api/comm/v1/system/Meetings`, {
        params: {
          interviewId: interviewId,
        }
      });
      console.log('API 完整響應:', response.data);

      if (response.data && Array.isArray(response.data.items)) {
        setMeetings(response.data.items);
        setTotalMeetings(response.data.total);
      } else {
        console.error('API 響應結構不符合預期:', response.data);
        setMeetings([]);
        setTotalMeetings(0);
        setError('獲取面試數據時出現問題');
      }
    } catch (error) {
      console.error('獲取面試列表失敗:', error);
      setError('獲取面試列表時發生錯誤');
      setMeetings([]);
      setTotalMeetings(0);
    } finally {
      setLoading(false);
    }
  }, [meetingsPerPage]);

  useEffect(() => {
    fetchMeetings(searchTerm, currentPage);
  }, [fetchMeetings, currentPage]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearch = () => {
    setCurrentPage(1);
    fetchMeetings(searchTerm, 1);
  };

  const handleReset = () => {
    setSearchTerm('');
    setCurrentPage(1);
    fetchMeetings('', 1);
  };

  const handleSearchKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSearch();
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('確定要刪除這家公司嗎？')) {
      try {
        await axios.delete(`${API_URL}/api/comm/v1/system/Corporation/${id}`);
        fetchMeetings(searchTerm, currentPage);
      } catch (error) {
        console.error('刪除面試失敗:', error);
      }
    }
  };

  const handleNewFileChange = (e) => {
    const { name, value } = e.target;
    setNewFile(prev => ({ ...prev, [name]: value }));
  };

  const handleAddFile = () => {
    if (newFile.fileType && newFile.upload) {
      setNewFile({ fileType: '', upload: '' });
    }
  };

  const handleEditFile = (index) => {
    setEditingIndex(index);
  };

  const handleSaveFile = () => {
    setEditingIndex(-1);
  };

  const handleDeleteFile = (index) => {
    if (window.confirm('確定要刪除嗎？')) {

    }
  };

  // 計算總頁數
  const totalPages = Math.ceil(totalMeetings / meetingsPerPage);

  // 改變頁面
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  // 分頁按鈕渲染邏輯
  const renderPaginationButtons = () => {
    const maxButtons = 5;
    let startPage, endPage;

    if (totalPages <= maxButtons) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= Math.ceil(maxButtons / 2)) {
        startPage = 1;
        endPage = maxButtons;
      } else if (currentPage + Math.floor(maxButtons / 2) >= totalPages) {
        startPage = totalPages - maxButtons + 1;
        endPage = totalPages;
      } else {
        startPage = currentPage - Math.floor(maxButtons / 2);
        endPage = currentPage + Math.floor(maxButtons / 2);
      }
    }

    return (
      <>
        {startPage > 1 && (
          <>
            <button onClick={() => paginate(1)} className="mx-1 px-3 py-1 border rounded">1</button>
            {startPage > 2 && <span className="mx-1">...</span>}
          </>
        )}
        {Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i).map(number => (
          <button
            key={number}
            onClick={() => paginate(number)}
            className={`mx-1 px-3 py-1 border rounded ${currentPage === number ? 'bg-blue-500 text-white' : 'bg-white'}`}
          >
            {number}
          </button>
        ))}
        {endPage < totalPages && (
          <>
            {endPage < totalPages - 1 && <span className="mx-1">...</span>}
            <button onClick={() => paginate(totalPages)} className="mx-1 px-3 py-1 border rounded">{totalPages}</button>
          </>
        )}
      </>
    );
  };

  // if (loading) return <div>加載中...</div>;
  // if (error) return <div>{error}</div>;

  return (
    <div className="mx-auto">
      <div className="flex justify-between items-center mb-6">
        <div>
          <span className="text-gray-400">檔案上傳</span>
          <h1 className="text-2xl font-semibold text-gray-800">PPSS檔案&薪資證明</h1>
        </div>
      </div>
      <div className='flex w-full'>
        <div className='flex-1'>
          <label className="text-gray-400">姓名</label>
          <div>王小明</div>
        </div>
        <div className='flex-1'>
          <label className="text-gray-400">Email</label>
          <div>test@gmail.com</div>
        </div>
        <div className='flex-1'>
          <label className="text-gray-400">公司</label>
          <div>零壹科技</div>
        </div>
        <div className='flex-1'>
          <label className="text-gray-400">部門</label>
          <div>資訊中心</div>
        </div>
        <div className='flex-1'>
          <label className="text-gray-400">應徵職務</label>
          <div>技術工程師</div>
        </div>
        <div className='flex-1'>
          <label className="text-gray-400">職務類別</label>
          <div>工程師</div>
        </div>
      </div>
      <div className="mt-4">
        <div className="overflow-x-auto">
          <table className="w-full border-collapse">
            <thead>
              <tr className="bg-gray-100 text-left">
                <th className="p-3 font-semibold text-sm text-gray-600">檔案類型</th>
                <th className="p-3 font-semibold text-sm text-gray-600">上傳</th>
                <th className="p-3 font-semibold text-sm text-gray-600">管理</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="p-2">
                  <select
                    name="fileType"
                    value={newFile.fileType}
                    onChange={handleNewFileChange}
                    className="w-full px-2 py-1 border border-gray-300 rounded-md"
                  >
                    <option value=""></option>
                    <option value="PPSS檔案">PPSS檔案</option>
                    <option value="薪資證明">薪資證明</option>
                  </select>
                </td>
                <td className="p-2">
                  <input
                    type="file"
                    name="address"
                    value={newFile.upload}
                    onChange={handleNewFileChange}
                    className="w-full px-2 py-1 border border-gray-300 rounded-md"
                  />
                </td>
                <td className="p-2">
                  <button
                    type="button"
                    onClick={handleAddFile}
                    className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300"
                  >
                    新增
                  </button>
                </td>
              </tr>
              {meetings.map((meeting, index) => (
                <tr key={meeting.id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                  <td className="p-3 text-sm text-gray-700">{meeting.fileType}</td>
                  <td className="p-3 text-sm text-gray-700">{meeting.uploadFile}</td>
                  <td className="p-3 text-sm">
                    {editingIndex === index ? (
                      <button
                        type="button"
                        onClick={handleSaveFile}
                        className="px-3 py-1 bg-green-500 text-white rounded-md hover:bg-green-600 transition duration-300"
                      >
                        保存
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={() => handleEditFile(index)}
                        className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300"
                      >
                        編輯
                      </button>
                    )}
                    <button
                      type="button"
                      onClick={() => handleDeleteFile(index)}
                      className="ml-2 px-3 py-1 bg-red-500 text-white rounded-md hover:bg-red-600 transition duration-300"
                    >
                      刪除
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {totalPages > 1 && (
          <div className="mt-6 flex justify-center items-center">
            <button
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
              className="mx-1 px-3 py-1 border rounded text-gray-600 disabled:opacity-50 hover:bg-gray-100 transition duration-300"
            >
              ←
            </button>
            {renderPaginationButtons()}
            <button
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="mx-1 px-3 py-1 border rounded text-gray-600 disabled:opacity-50 hover:bg-gray-100 transition duration-300"
            >
              →
            </button>
          </div>
        )}
      </div>

      <div className="flex justify-center space-x-3 mt-10">
        <button type="button" onClick={() => navigate('/interviews')} className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-100 transition duration-300">
          返回
        </button>
        <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300">
          儲存
        </button>
      </div>
    </div>
  );
}

export default Meetings;
