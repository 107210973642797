import React from 'react';

function Home() {
  const logo = require('../../assets/zerone-logo.png');

  return (
    <div className='flex justify-center items-center h-full'>
      <div>
        <img src={logo} alt="零壹科技eHRM" width={150} />
        <h1 className="font-bold text-center text-3xl text-gray-800">eHRM</h1>
      </div>
    </div>
  );
}

export default Home;
