import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaSortUp, FaSortDown, FaFilter } from 'react-icons/fa';

function Admissions() {
  const [companies, setCompanies] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [companiesPerPage] = useState(25);
  const [totalCompanies, setTotalCompanies] = useState(0);
  // const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [query, setQuery] = useState("");
  const [isSelectAll, setIsSelectAll] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]); 
  const [showTable, setShowTable] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [sortOrder, setSortOrder] = useState("unsorted");
  const [filterKey, setFilterKey] = useState("name");
  const [data, setData] = useState([{
              id: 1,
              name: '王小明',
              email: 'wangsmallming@gmail.com',
              appliedCompany: '零壹科技',
              appliedDepartment: '軟體開發部',
              appliedPosition: '軟體工程師',
              admissionTime: '2024-01-01 13:50',
              notifyCompleteTime: '2024-01-01 13:50',
              selected: true,
              visible: true
            },{
              id: 2,
              name: '陳小美',
              email: 'chenxiaomei@gmail.com',
              appliedCompany: '零壹科技',
              appliedDepartment: '人力資源部',
              appliedPosition: 'HR',
              admissionTime: '2024-01-01 13:50',
              notifyCompleteTime: '2024-01-01 13:50',
              selected: true,
              visible: true
            }]);
  const [filteredData, setFilteredData] = useState(companies);

  // const fetchCompanies = useCallback(async (search, page) => {
  //   setLoading(true);
  //   setError(null);
  //   try {
  //     // const response = await axios.get(`${API_URL}/api/comm/v1/system/Corporations`, {
  //     //   // params: {
  //     //   //   page: page,
  //     //   //   limit: companiesPerPage,
  //     //   //   search: search
  //     //   // }
  //     // });
  //     const response = {
  //       data: {
  //         items: [{
  //           id: 1,
  //           name: '王小明',
  //           email: 'wangsmallming@gmail.com',
  //           appliedCompany: '零壹科技',
  //           appliedDepartment: '軟體開發部',
  //           appliedPosition: '軟體工程師',
  //           admissionTime: '2024-01-01 13:50',
  //           notifyCompleteTime: '2024-01-01 13:50',
  //           selected: true,
  //           visible: true
  //         },{
  //           id: 2,
  //           name: '陳小美',
  //           email: 'chenxiaomei@gmail.com',
  //           appliedCompany: '零壹科技',
  //           appliedDepartment: '人力資源部',
  //           appliedPosition: 'HR',
  //           admissionTime: '2024-01-01 13:50',
  //           notifyCompleteTime: '2024-01-01 13:50',
  //           selected: true,
  //           visible: true
  //         }]
  //       }
  //     };
  //     console.log('API 完整響應:', response.data);

  //     if (response.data && Array.isArray(response.data.items)) {
  //       setCompanies(response.data.items);
  //       setTotalCompanies(response.data.total);
  //     } else {
  //       console.error('API 響應結構不符合預期:', response.data);
  //       setCompanies([]);
  //       setTotalCompanies(0);
  //       setError('獲取公司數據時出現問題');
  //     }
  //   } catch (error) {
  //     console.error('獲取公司列表失敗:', error);
  //     setError('獲取公司列表時發生錯誤');
  //     setCompanies([]);
  //     setTotalCompanies(0);
  //   } finally {
  //     setLoading(false);
  //   }
  // }, [companiesPerPage]);

  // useEffect(() => {
  //   fetchCompanies(searchTerm, currentPage);
  // }, [fetchCompanies, currentPage]);

  const handleSort = useCallback(() => {
    setSortOrder((prevOrder) => {
      switch (prevOrder) {
        case "unsorted":
          return "asc";
        case "asc":
          return "desc";
        default:
          return "unsorted";
      }
    });
  }, []);
  
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    const sortedData = [...data]
      .filter((row) => row[filterKey].toLowerCase().includes(query.toLowerCase()))
      .map((row) => ({ ...row, visible: row.selected }))
      .sort((a, b) => {
        if (sortConfig.direction === "unsorted") return 0;
        return sortConfig.direction === "asc"
          ? a[filterKey].localeCompare(b[filterKey])
          : b[filterKey].localeCompare(a[filterKey]);
      });

    setFilteredData(sortedData);
  }, [data, query, sortConfig]);

  // const handleSearch = () => {
  //   setCurrentPage(1);
  //   fetchCompanies(searchTerm, 1);
  // };

  const handleSearch = useCallback((e) => {
    setQuery(e.target.value);
  }, []);

  // const handleReset = () => {
  //   setSearchTerm('');
  //   setCurrentPage(1);
  //   fetchCompanies('', 1);
  // };

  // const handleSearchKeyDown = (e) => {
  //   if (e.key === 'Enter') {
  //     e.preventDefault();
  //     handleSearch();
  //   }
  // };

  // const handleDelete = async (id) => {
  //   if (window.confirm('確定要刪除這家公司嗎？')) {
  //     try {
  //       await axios.delete(`${API_URL}/api/comm/v1/system/Corporation/${id}`);
  //       fetchCompanies(searchTerm, currentPage);
  //     } catch (error) {
  //       console.error('刪除公司失敗:', error);
  //     }
  //   }
  // };

  // 計算總頁數
  const totalPages = Math.ceil(totalCompanies / companiesPerPage);

  // 改變頁面
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  // 分頁按鈕渲染邏輯
  const renderPaginationButtons = () => {
    const maxButtons = 5;
    let startPage, endPage;

    if (totalPages <= maxButtons) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= Math.ceil(maxButtons / 2)) {
        startPage = 1;
        endPage = maxButtons;
      } else if (currentPage + Math.floor(maxButtons / 2) >= totalPages) {
        startPage = totalPages - maxButtons + 1;
        endPage = totalPages;
      } else {
        startPage = currentPage - Math.floor(maxButtons / 2);
        endPage = currentPage + Math.floor(maxButtons / 2);
      }
    }

    return (
      <>
        {startPage > 1 && (
          <>
            <button onClick={() => paginate(1)} className="mx-1 px-3 py-1 border rounded">1</button>
            {startPage > 2 && <span className="mx-1">...</span>}
          </>
        )}
        {Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i).map(number => (
          <button
            key={number}
            onClick={() => paginate(number)}
            className={`mx-1 px-3 py-1 border rounded ${currentPage === number ? 'bg-blue-500 text-white' : 'bg-white'}`}
          >
            {number}
          </button>
        ))}
        {endPage < totalPages && (
          <>
            {endPage < totalPages - 1 && <span className="mx-1">...</span>}
            <button onClick={() => paginate(totalPages)} className="mx-1 px-3 py-1 border rounded">{totalPages}</button>
          </>
        )}
      </>
    );
  };

  const toggleTableVisibility = useCallback(() => {
    setShowTable((prev) => !prev);
  }, []);

  const toggleFilterVisibility = useCallback(() => {
    setShowFilter((prev) => !prev);
  }, []);

  // 新增排序功能
  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key) {
      if (sortConfig.direction === 'asc') {
        direction = 'desc';
      } else if (sortConfig.direction === 'desc') {
        direction = ''; // 預設狀態
      }
    }
    setSortConfig({ key, direction });
  };

  // 排序公司資料
  const sortedCompanies = () => {
    if (sortConfig.key) {
      return [...companies].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return companies; // 如果沒有排序，返回原始資料
  };

  const handleSelectAll = useCallback((e) => {
    const checked = e.target.checked;
    setIsSelectAll(checked);
    setData((prevData) =>
      prevData.map((row) => ({
        ...row,
        selected: checked,
        visible: checked,
      }))
    );
  }, []);

  const handleSelectRow = useCallback((id) => {
    setData((prevData) =>
      prevData.map((row) =>
        row.id === id
          ? {
              ...row,
              selected: !row.selected,
              visible: row.selected,
            }
          : row
      )
    );
  }, []);

  // if (loading) return <div>加載中...</div>;
  // if (error) return <div>{error}</div>;

  return (
    <div className="mx-auto">
      <div className="flex justify-between items-center mb-6">
        <div>
          <span className="text-gray-400">錄取管理</span>
          <h1 className="text-2xl font-semibold">錄取列表</h1>
        </div>
      </div>
      <div className="rounded-lg bg-white">
        {/* <div className="mb-6 flex flex-col justify-between items-center md:flex-row">
          <div className="flex flex-col md:flex-row">
            <input
              type="text"
              placeholder="以姓名/Email進行查詢"
              className="p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 mb-2 md:mb-0 md:mr-2"
              value={searchTerm}
              onChange={handleSearchChange}
              onKeyDown={handleSearchKeyDown}
            />
            <button
              onClick={handleSearch}
              className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300 flex items-center mb-2 md:mb-0"
            >
              <FaSearch className="mr-2" />
              查詢
            </button>
          </div>
          <div className="flex flex-col md:flex-row">
          </div>
        </div> */}
        <div>
          <table className="w-full border-collapse">
            <thead>
              <tr className="bg-gray-100 text-center select-none">
                <th className="p-3 font-semibold text-sm text-gray-600 cursor-pointer text-center relative min-w-[100px]">
                  <div className="flex items-center justify-center">
                    <div className="flex items-center justify-center" onClick={() => requestSort('name')}>
                      {sortConfig.key === 'name' && sortConfig.direction === 'asc' ? (
                        <FaSortUp className="mr-1" />
                      ) : sortConfig.key === 'name' && sortConfig.direction === 'desc' ? (
                        <FaSortDown className="mr-1" />
                      ) : null} {}
                      姓名
                    </div>
                    {/* <div className="absolute right-0">
                      <FaFilter className="mr-1" onClick={toggleFilterVisibility} />
                    </div> */}
                  </div>
                  <div className="input-container absolute text-left border-2 border-blue-100 shadow-md rounded-md p-4 bg-blue-100" style={{ opacity: showFilter ? "1" : "0" }}>
                    <div>
                      <input
                        type="text"
                        placeholder="搜尋..."
                        value={query}
                        onChange={handleSearch}
                        className="input border-2 border-gray-300 rounded-md p-2 mb-2"
                      />
                    </div>
                    <div className="datalist-container">
                      <ul className="datalist">
                        <li className="datalist-item pb-1">
                          <input
                            type="checkbox"
                            checked={isSelectAll}
                            onChange={handleSelectAll}
                            className="checkbox mr-1"
                          />
                          Select All
                        </li>
                        {filteredData.map((row) => (
                          <li key={row.id} className="datalist-item pb-1">
                            <input
                              type="checkbox"
                              checked={row.selected}
                              onChange={() => handleSelectRow(row.id)}
                              className="checkbox mr-1"
                            />
                            {row.name}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </th>
                <th className="p-3 font-semibold text-sm text-gray-600 cursor-pointer text-center">
                  <div className="flex items-center justify-center" onClick={() => requestSort('email')}>
                    {sortConfig.key === 'email' && sortConfig.direction === 'asc' ? (
                      <FaSortUp className="mr-1" />
                    ) : sortConfig.key === 'email' && sortConfig.direction === 'desc' ? (
                      <FaSortDown className="mr-1" />
                    ) : null} {/* 當為預設狀態時不顯示圖示 */}
                    Email
                  </div>
                </th>
                <th className="p-3 font-semibold text-sm text-gray-600 cursor-pointer text-center">
                  <div className="flex items-center justify-center" onClick={() => requestSort('appliedCompany')}>
                    {sortConfig.key === 'appliedCompany' && sortConfig.direction === 'asc' ? (
                      <FaSortUp className="mr-1" />
                    ) : sortConfig.key === 'appliedCompany' && sortConfig.direction === 'desc' ? (
                      <FaSortDown className="mr-1" />
                    ) : null} {/* 當為預設狀態時不顯示圖示 */}
                    應徵公司
                  </div>
                </th>
                <th className="p-3 font-semibold text-sm text-gray-600 cursor-pointer text-center">
                  <div className="flex items-center justify-center" onClick={() => requestSort('appliedDepartment')}>
                    {sortConfig.key === 'appliedDepartment' && sortConfig.direction === 'asc' ? (
                        <FaSortUp className="mr-1" />
                      ) : sortConfig.key === 'appliedDepartment' && sortConfig.direction === 'desc' ? (
                        <FaSortDown className="mr-1" />
                      ) : null} {/* 當為預設狀態時不顯示圖示 */}
                    應徵部門
                  </div>
                </th>
                <th className="p-3 font-semibold text-sm text-gray-600 cursor-pointer text-center">
                  <div className="flex items-center justify-center" onClick={() => requestSort('appliedPosition')}>
                    {sortConfig.key === 'appliedPosition' && sortConfig.direction === 'asc' ? (
                        <FaSortUp className="mr-1" />
                      ) : sortConfig.key === 'appliedPosition' && sortConfig.direction === 'desc' ? (
                        <FaSortDown className="mr-1" />
                      ) : null} {/* 當為預設狀態時不顯示圖示 */}
                    應徵職務
                  </div>
                </th>
                <th className="p-3 font-semibold text-sm text-gray-600 cursor-pointer text-center">
                  <div className="flex items-center justify-center" onClick={() => requestSort('admissionTime')}>
                    {sortConfig.key === 'admissionTime' && sortConfig.direction === 'asc' ? (
                        <FaSortUp className="mr-1" />
                      ) : sortConfig.key === 'admissionTime' && sortConfig.direction === 'desc' ? (
                        <FaSortDown className="mr-1" />
                      ) : null} {/* 當為預設狀態時不顯示圖示 */}
                    錄取時間
                  </div>
                </th>
                <th className="p-3 font-semibold text-sm text-gray-600 cursor-pointer text-center">
                  <div className="flex items-center justify-center" onClick={() => requestSort('notifyCompleteTime')}>
                    {sortConfig.key === 'notifyCompleteTime' && sortConfig.direction === 'asc' ? (
                        <FaSortUp className="mr-1" />
                      ) : sortConfig.key === 'notifyCompleteTime' && sortConfig.direction === 'desc' ? (
                        <FaSortDown className="mr-1" />
                      ) : null} {/* 當為預設狀態時不顯示圖示 */}
                    通知完成時間
                  </div>
                </th>
                <th className="p-3 font-semibold text-sm text-gray-600">管理</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((company, index) => company.visible && (
                <tr key={company.id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                  <td className="p-3 text-sm text-gray-700 text-center">
                    <Link to={`/application-info?interviewId=E57B270C-01A5-44D5-93FB-003D9DF9C66D`} className="text-blue-500 mr-2 hover:underline">
                      {company.name}
                    </Link>
                  </td>
                  <td className="p-3 text-sm text-gray-700 text-center">{company.email}</td>
                  <td className="p-3 text-sm text-gray-700 text-center">{company.appliedCompany}</td>
                  <td className="p-3 text-sm text-gray-700 text-center">{company.appliedDepartment}</td>
                  <td className="p-3 text-sm text-gray-700 text-center">{company.appliedPosition}</td>
                  <td className="p-3 text-sm text-gray-700 text-center">{company.admissionTime}</td>
                  <td className="p-3 text-sm text-gray-700 text-center">{company.notifyCompleteTime}</td>
                  <td className="p-3 text-sm text-center">
                    <div>
                      <Link to={`/admission/notify/${company.id}`} className="text-blue-500 mr-2 hover:underline">錄取通知</Link>
                      <Link to={`/admission/profile/${company.id}`} className="text-blue-500 mr-2 hover:underline">人事資料</Link>
                    </div>
                    <div>
                      <Link to={`/admission/set-up-upload-files/${company.id}`} className="text-blue-500 mr-2 hover:underline">設定上傳文件</Link>
                      <Link to={`/admission/hrm/${company.id}`} className="text-blue-500 mr-2 hover:underline">轉HRM</Link>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {totalPages > 1 && (
          <div className="mt-6 flex justify-center items-center">
            <button
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
              className="mx-1 px-3 py-1 border rounded text-gray-600 disabled:opacity-50 hover:bg-gray-100 transition duration-300"
            >
              ←
            </button>
            {renderPaginationButtons()}
            <button
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="mx-1 px-3 py-1 border rounded text-gray-600 disabled:opacity-50 hover:bg-gray-100 transition duration-300"
            >
              →
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Admissions;
