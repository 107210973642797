import React, { useState, useEffect, useCallback } from 'react';
import { deleteEvaluationcriteria, getEvaluationcriteria, createEvaluationcriteria, updateEvaluationcriteria } from '../../services/api';
import { FiSearch, FiEdit2 } from 'react-icons/fi';
import { RiDeleteBinLine } from "react-icons/ri";
import { FaRegSave } from "react-icons/fa";
import { AiOutlineRollback } from "react-icons/ai";
import Select from 'react-select';

function InterviewEvaluation() {
  const [category, setCategory] = useState('');
  const [position, setPosition] = useState('');
  const [editEvaluation, setEditEvaluation] = useState('');
  const [role, setRole] = useState('');
  const [newRole, setNewRole] = useState('');
  const [jobCategory, setJobCategory] = useState('');
  const [newJobCategory, setNewJobCategory] = useState('');
  const [evaluations, setEvaluations] = useState([]);
  const [evaluationItem, setEvaluationItem] = useState('');
  const [order, setOrder] = useState('');
  const [editIndex, setEditIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedRoleOption, setSelectedRoleOption] = useState(null);
  const [selectedPositionOption, setSelectedPositionOption] = useState(null);
  const [newRoleOption, setNewRoleOption] = useState(null);
  const [newPositionOption, setNewPositionOption] = useState(null);

  const roleOptions = [
    { value: 'HR', label: 'HR' },
    { value: '前端開發', label: '前端開發' },
    { value: '後端開發', label: '後端開發' },
    { value: '全端開發', label: '全端開發' }
  ];

  const positionOptions = [
    { value: '工程師', label: '工程師' },
    { value: '設計師', label: '設計師' },
    { value: '業務', label: '業務' }
  ];

  const newRoleOptions = [
    { value: 'HR', label: 'HR' },
    { value: '前端開發', label: '前端開發' },
    { value: '後端開發', label: '後端開發' },
    { value: '全端開發', label: '全端開發' }
  ];

  const newPositionOptions = [
    { value: '工程師', label: '工程師' },
    { value: '設計師', label: '設計師' },
    { value: '業務', label: '業務' }
  ];

  const handleEditSelectChange = (selectedOptions) => {
    setEditEvaluation(selectedOptions.map(option => option.value));
  };

  const handleSearch = () => {
    console.log('查詢:', { selectedRoleOption, selectedPositionOption });
  };

  const fetchEvaluationcriteria = useCallback(async (category, position) => {
    setLoading(true);
    setError(null);
    
    try {
      const response = await getEvaluationcriteria(category, position);
      console.log(response);
      setEvaluations(response.items);
      console.log('API 完整響應:', response);
    } catch (error) {
      console.error('獲取評估標準失敗:', error);
    } finally {
      setLoading(false);
    }
  }, [getEvaluationcriteria, category, position]);

  useEffect(() => {
    fetchEvaluationcriteria(category, position);
  }, [fetchEvaluationcriteria, category, position]);

  const handleAdd = () => {
    if (newRoleOption && newPositionOption && evaluationItem && order) {
      setEvaluations([...evaluations, { categoryName: newRoleOption.value, positionName: newPositionOption.value, criteria: evaluationItem, order }]);
      setNewRoleOption('');
      setNewPositionOption('');
      setEvaluationItem('');
      setOrder('');
      createEvaluationcriteria({ categoryName: category, positionName: position, criteria: evaluationItem, order: order });
    }
  };

  const handleEditSave = () => {
    if (editIndex !== null) {
      const updatedEvaluations = [...evaluations];
      updatedEvaluations[editIndex] = editEvaluation;
      setEvaluations(updatedEvaluations);
      setEditIndex(null);
      updateEvaluationcriteria(editEvaluation.id, { categoryName: editEvaluation.categoryName, positionName: editEvaluation.positionName, criteria: editEvaluation.criteria, order: editEvaluation.order });
    } else {
      setEvaluations([...evaluations, editEvaluation]);
      console.log(2)
    }
    setEditEvaluation('');
  };

  const handleAddCancel = () => {
    setEditIndex(null);
  };

  const handleEdit = (index) => {
    setEditEvaluation(evaluations[index]);
    setEditIndex(index);
  };

  const handleDelete = (index) => {
    if (window.confirm('您確定要刪除這個來源嗎？')) {
      const updatedEvaluations = evaluations.filter((_, i) => i !== index);
      setEvaluations(updatedEvaluations);
      deleteEvaluationcriteria(evaluations[index].id);
    }
  };

  return (
    <div className="mx-auto">
      <div className="flex justify-between items-center mb-6">
        <div>
          <span className="text-gray-400">系統管理</span>
          <h1 className="text-2xl font-semibold text-gray-800">面試評核項目列表</h1>
        </div>
      </div>
      <div>
        <div className="grid grid-cols-1 md:grid-cols-6 gap-4 mb-6">
          <div>
            <Select 
              options={roleOptions} 
              value={selectedRoleOption}
              placeholder="面試者角色"
              onChange={setSelectedRoleOption}
              styles={{
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isSelected ? 'inherit' : '#F3F4F6',
                  '&:hover': { backgroundColor: '#FFF' },
                  color: state.isSelected ? '#000' : 'inherit',
                })
              }}
            />
          </div>
          <div>
            <Select 
              options={positionOptions} 
              value={selectedPositionOption}
              placeholder="職務類別"
              onChange={setSelectedPositionOption}
              styles={{
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isSelected ? 'inherit' : '#F3F4F6',
                  '&:hover': { backgroundColor: '#FFF' },
                  color: state.isSelected ? '#000' : 'inherit',
                })
              }}
            />
          </div>
          <div>
            <button
              onClick={handleSearch}
              className="btn-search hover:btn-search px-4 py-1 bg-blue-500 text-white rounded-md flex items-center"
            >
              <FiSearch className="mr-1" />
              查詢
            </button>
          </div>
        </div>
        
        <div>
          <table className="w-full border-collapse border border-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">面試者角色</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">職務類別</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">評測項目</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">順序</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">管理</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              <tr>
                <td className="px-4 py-2">
                  <Select 
                    options={roleOptions} 
                    value={newRoleOption}
                    placeholder="面試者角色"
                    onChange={setNewRoleOption}
                    styles={{
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected ? 'inherit' : '#F3F4F6',
                        '&:hover': { backgroundColor: '#FFF' },
                        color: state.isSelected ? '#000' : 'inherit',
                      })
                    }}
                  />
                </td>
                <td className="px-4 py-2">
                  <Select 
                    options={positionOptions} 
                    value={newPositionOption}
                    placeholder="職務類別"
                    onChange={setNewPositionOption}
                    styles={{
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected ? 'inherit' : '#F3F4F6',
                        '&:hover': { backgroundColor: '#FFF' },
                        color: state.isSelected ? '#000' : 'inherit',
                      })
                    }}
                  />
                </td>
                <td className="px-4 py-2">
                  <input
                    type="text"
                    className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={evaluationItem}
                    onChange={(e) => setEvaluationItem(e.target.value)}
                  />
                </td>
                <td className="px-4 py-2">
                  <input
                    type="text"
                    className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={order}
                    onChange={(e) => setOrder(e.target.value)}
                  />
                </td>
                <td className="px-4 py-2">
                  <button
                    onClick={handleAdd}
                    className="btn-new hover:btn-new px-3 py-1 bg-blue-500 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  >
                    新增
                  </button>
                </td>
              </tr>
              {evaluations.map((evaluation, index) => (
                <tr key={index}>
                  <td className="px-4 py-2">
                    {editIndex === index ? (
                      <Select 
                        value={{ value: evaluation.categoryName, label: evaluation.categoryName }}
                        options={roleOptions}
                        onChange={handleEditSelectChange}
                        placeholder="面試者角色"
                        styles={{
                          option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isSelected ? 'inherit' : '#F3F4F6',
                            '&:hover': { backgroundColor: state.isSelected ? 'inherit' : '#FFF' },
                            color: state.isSelected ? '#000' : 'inherit',
                          })
                        }}
                      />
                    ) : (
                      evaluation.categoryName
                    )}
                  </td>
                  <td className="px-4 py-2">
                    {editIndex === index ? (
                      <Select 
                        options={positionOptions} 
                        value={{ value: evaluation.positionName, label: evaluation.positionName }}
                        placeholder="職務類別"
                        styles={{
                          option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isSelected ? 'inherit' : '#F3F4F6',
                            '&:hover': { backgroundColor: '#FFF' },
                            color: state.isSelected ? '#000' : 'inherit',
                          })
                        }}
                      />
                    ) : (
                      evaluation.positionName
                    )}
                  </td>
                  <td className="px-4 py-2">
                    {editIndex === index ? (
                      <input
                        type="text"
                        className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        value={editEvaluation.criteria}
                        onChange={(e) => setEditEvaluation({...editEvaluation, criteria: e.target.value})}
                      />
                    ) : (
                      evaluation.criteria
                    )}
                  </td>
                  <td className="px-4 py-2">
                    {editIndex === index ? (
                      <input
                        type="text"
                        className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        value={editEvaluation.order}
                        onChange={(e) => setEditEvaluation({...editEvaluation, order: e.target.value})}
                      />
                    ) : (
                      evaluation.order
                    )}
                  </td>
                  <td className="px-4 py-2">
                    {editIndex === index ? (
                      <div>
                        <button
                          onClick={handleEditSave}
                          className="btn-icon px-3 py-1 rounded-md"
                        >
                          <FaRegSave />
                        </button>
                        <button
                          onClick={handleAddCancel}
                          className="btn-icon ml-2 px-3 py-1 rounded-md"
                        >
                          <AiOutlineRollback />
                        </button>
                      </div>
                    ) : (
                      <div>
                        <button
                          onClick={() => handleEdit(index)}
                          className="btn-icon px-3 py-1 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 mr-2"
                        >
                          <FiEdit2 />
                        </button>
                        <button
                          onClick={() => handleDelete(index)}
                          className="btn-icon px-3 py-1 rounded-md"
                        >
                          <RiDeleteBinLine />
                        </button>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default InterviewEvaluation;
