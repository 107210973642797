import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { FiEdit2 } from 'react-icons/fi';
import { RiDeleteBinLine } from "react-icons/ri";
import { FaRegSave } from "react-icons/fa";
import { AiOutlineRollback } from "react-icons/ai";

function InterviewMemo() {
  const [formData, setFormData] = useState({
    previousManagers: [],
  });
  const navigate = useNavigate();
  const id = useLocation().search.split('interviewId=')[1];
  const [newManager, setNewManager] = useState({ name: '', company: '', relationship: '', title: '', phone: '' });
  const [editingIndex, setEditingIndex] = useState(-1);

  useEffect(() => {
    console.log('formData:', formData);
  }, [formData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleNewManagerChange = (e) => {
    const { name, value } = e.target;
    setNewManager(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('保存的數據:', formData);
    navigate(`/application-info/family-status?interviewId=${id}`);
  }

  const handleAddManager = () => {
    if (newManager.name && newManager.company && newManager.relationship && newManager.title && newManager.phone) {
      setFormData(prev => ({
        ...prev,
        previousManagers: [...prev.previousManagers, newManager]
      }));
      setNewManager({ name: '', company: '', relationship: '', title: '', phone: '' });
    }
  };

  const handleChange = (index, field, value) => {
    const updatedManagers = formData.previousManagers.map((loc, i) => 
      i === index ? { ...loc, [field]: value } : loc
    );
    setFormData({ ...formData, previousManagers: updatedManagers });
  };

  const handleEditManager = (index) => {
    setEditingIndex(index);
  };

  const handleSaveManager = () => {
    setEditingIndex(-1);
  };

  const handleCancelManager = () => {
    setEditingIndex(-1);
  };

  const handleDeleteManager = (index) => {
    if (window.confirm('確定要刪除嗎？')) {
      try {
        const updatedManagers = formData.previousManagers.filter((_, i) => i !== index);
        setFormData({ ...formData, previousManagers: updatedManagers });
      } catch (error) {
        console.error('刪除公司失敗:', error);
      }
    }
  };

  return (
    <div id="application-info" className="max-w-screen-2xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <div>
          <span className="text-gray-400">應徵者輸入應徵資料</span>
          <h1 className="text-2xl font-semibold text-gray-800">面試者填寫應徵者前公司主管</h1>
        </div>
      </div>
      <div className="bg-white shadow-sm rounded-lg p-6 max-w-6xl">
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-lg font-medium text-gray-700">前公司主管(供資歷查核使用)</label>
            <table className="w-full">
              <thead>
                <tr className="bg-gray-100 text-center">
                  <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider w-1/6">姓名</th>
                  <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider w-1/6">公司名稱</th>
                  <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider w-1/6">關係</th>
                  <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider w-1/6">職稱</th>
                  <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider w-1/6">電話</th>
                  <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider w-1/6">管理</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="p-2">
                    <input
                      type="text"
                      name="name"
                      value={newManager.name}
                      onChange={handleNewManagerChange}
                      className="w-full px-2 py-1 border border-gray-300 rounded-md"
                    />
                  </td>
                  <td className="p-2">
                    <input
                      type="text"
                      name="company"
                      value={newManager.company}
                      onChange={handleNewManagerChange}
                      className="w-full px-2 py-1 border border-gray-300 rounded-md"
                    />
                  </td>
                  <td className="p-2">
                    <input
                      type="text"
                      name="relationship"
                      value={newManager.relationship}
                      onChange={handleNewManagerChange}
                      className="w-full px-2 py-1 border border-gray-300 rounded-md"
                    />
                  </td>
                  <td className="p-2">
                    <input
                      type="text"
                      name="title"
                      value={newManager.title}
                      onChange={handleNewManagerChange}
                      className="w-full px-2 py-1 border border-gray-300 rounded-md"
                    />
                  </td>
                  <td className="p-2">
                    <input
                      type="text"
                      name="phone"
                      value={newManager.phone}
                      onChange={handleNewManagerChange}
                      className="w-full px-2 py-1 border border-gray-300 rounded-md"
                    />
                  </td>
                  <td className="p-2 text-center">
                    <button
                      type="button"
                      onClick={handleAddManager}
                      className="btn-new hover:btn-new px-3 py-1 text-white rounded-md transition duration-300"
                    >
                      新增
                    </button>
                  </td>
                </tr>
                {formData.previousManagers.map((manager, index) => (
                  <tr key={index} className="border-b border-gray-200">
                    <td className="p-2">
                      {editingIndex === index ? (
                        <input
                          type="text"
                          value={manager.name}
                          onChange={(e) => handleChange(index, 'name', e.target.value)}
                          className="w-full px-2 py-1 border border-gray-300 rounded-md"
                        />
                      ) : (
                        manager.name
                      )}
                    </td>
                    <td className="p-2">
                      {editingIndex === index ? (
                        <input
                          type="text"
                          value={manager.company}
                          onChange={(e) => handleChange(index, 'company', e.target.value)}
                          className="w-full px-2 py-1 border border-gray-300 rounded-md"
                        />
                      ) : (
                        manager.company
                      )}
                    </td>
                    <td className="p-2">
                      {editingIndex === index ? (
                        <input
                          type="text"
                          value={manager.relationship}
                          onChange={(e) => handleChange(index, 'relationship', e.target.value)}
                          className="w-full px-2 py-1 border border-gray-300 rounded-md"
                        />
                      ) : (
                        manager.relationship
                      )}
                    </td>
                    <td className="p-2">
                      {editingIndex === index ? (
                        <input
                          type="text"
                          value={manager.title}
                          onChange={(e) => handleChange(index, 'title', e.target.value)}
                          className="w-full px-2 py-1 border border-gray-300 rounded-md"
                        />
                      ) : (
                        manager.title
                      )}
                    </td>
                    <td className="p-2">
                      {editingIndex === index ? (
                        <input
                          type="text"
                          value={manager.phone}
                          onChange={(e) => handleChange(index, 'phone', e.target.value)}
                          className="w-full px-2 py-1 border border-gray-300 rounded-md"
                        />
                      ) : (
                        manager.phone
                      )}
                    </td>
                    <td className="p-2 text-center">
                      {editingIndex === index ? (
                        <>
                          <button
                            type="button"
                            onClick={handleSaveManager}
                            className="px-2 py-2 rounded-md btn-icon"
                          >
                            <FaRegSave />
                          </button>
                          <button
                            type="button"
                            onClick={() => handleCancelManager()}
                            className="ml-2 px-2 py-2 rounded-md btn-icon"
                          >
                            <AiOutlineRollback />
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="button"
                            onClick={() => handleEditManager(index)}
                            className="px-2 py-2 rounded-md btn-icon"
                          >
                            <FiEdit2 />
                          </button>
                          <button
                          type="button"
                          onClick={() => handleDeleteManager(index)}
                          className="ml-2 px-2 py-2 rounded-md btn-icon"
                        >
                          <RiDeleteBinLine />
                        </button>
                      </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex justify-center space-x-2">
            <button type="reset" className="px-4 py-2 border rounded-md text-gray-600 hover:bg-gray-100">
              清除
            </button>
            <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
              儲存
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default InterviewMemo;
