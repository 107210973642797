import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaSearch, FaSortUp, FaSortDown } from 'react-icons/fa';
import { get } from '../../services/api';

function Onboards() {
  const [companies, setCompanies] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [companiesPerPage] = useState(25);
  const [totalCompanies, setTotalCompanies] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [isNotifying, setIsNotifying] = useState(false);
  const [isGeneratingHTML, setIsGeneratingHTML] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const fetchCompanies = useCallback(async (searchTerm, currentPage) => {
    setLoading(true);
    setError(null);
    try {
      const response = await get(`/api/ehrm/v1/OnBoards`, {
        currentPage: currentPage,
        pageSize: 20,
        name: searchTerm,
        email: searchTerm
      });
      console.log('API 完整響應:', response);

      if (response && Array.isArray(response)) {
        setCompanies(response);
        setTotalCompanies(response.length);
      } else {
        console.error('API 響應結構不符合預期:', response);
        setCompanies([]);
        setTotalCompanies(0);
        setError('獲取數據時出現問題');
      }
    } catch (error) {
      console.error('獲取列表失敗:', error);
      setError('獲取列表時發生錯誤');
      setCompanies([]);
      setTotalCompanies(0);
    } finally {
      setLoading(false);
    }
  }, [companiesPerPage]);

  useEffect(() => {
    fetchCompanies(searchTerm, currentPage);
  }, [fetchCompanies, currentPage]);


  const handleSearch = () => {
    setCurrentPage(1);
    fetchCompanies(searchTerm, 1);
  };

  const totalPages = Math.ceil(totalCompanies / companiesPerPage);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const renderPaginationButtons = () => {
    const maxButtons = 5;
    let startPage, endPage;

    if (totalPages <= maxButtons) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= Math.ceil(maxButtons / 2)) {
        startPage = 1;
        endPage = maxButtons;
      } else if (currentPage + Math.floor(maxButtons / 2) >= totalPages) {
        startPage = totalPages - maxButtons + 1;
        endPage = totalPages;
      } else {
        startPage = currentPage - Math.floor(maxButtons / 2);
        endPage = currentPage + Math.floor(maxButtons / 2);
      }
    }

    return (
      <>
        {startPage > 1 && (
          <>
            <button onClick={() => paginate(1)} className="mx-1 px-3 py-1 border rounded">1</button>
            {startPage > 2 && <span className="mx-1">...</span>}
          </>
        )}
        {Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i).map(number => (
          <button
            key={number}
            onClick={() => paginate(number)}
            className={`mx-1 px-3 py-1 border rounded ${currentPage === number ? 'bg-blue-500 text-white' : 'bg-white'}`}
          >
            {number}
          </button>
        ))}
        {endPage < totalPages && (
          <>
            {endPage < totalPages - 1 && <span className="mx-1">...</span>}
            <button onClick={() => paginate(totalPages)} className="mx-1 px-3 py-1 border rounded">{totalPages}</button>
          </>
        )}
      </>
    );
  };

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key) {
      if (sortConfig.direction === 'asc') {
        direction = 'desc';
      } else if (sortConfig.direction === 'desc') {
        direction = ''; // 預設狀態
      }
    }
    setSortConfig({ key, direction });
  };

  const sortedCompanies = () => {
    if (sortConfig.key) {
      return [...companies].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return companies;
  };

  if (loading) return <div>加載中...</div>;
  if (error) return <div>{error}</div>;

  const notifyOnboardData = () => {
    console.log('補報到資料通知');
  };

  const generatingHTMLAPI = async () => {
    const params = selectedRows.map(row => ({ "onBoardId": row.onBoardId }));
    // const response = await get('/api/ehrm/v1/OnBoards/{id}/Admission/Introductions', params);
    console.log('產出HTML', params);
  };

  const generateSelfIntroductionHTML = () => {
    setIsGeneratingHTML(true);
  };

  const handleNotifyClick = () => {
    setIsNotifying(true);
    notifyOnboardData();
  };

  const handleCancelNotify = () => {
    setIsNotifying(false);
  };

  const handleMailNotify = () => {
    console.log('寄出通知');
  };

  const handleCancelHTMLGeneration = () => {
    setIsGeneratingHTML(false);
  };

  const toggleSelect = (company) => {
    setSelectedRows(prevSelected => prevSelected.includes(company) 
      ? prevSelected.filter(row => row !== company) 
      : [...prevSelected, company]);
  };

  return (
    <div className="mx-auto">
      <div className="flex justify-between items-center mb-6">
        <div>
          <span className="text-gray-400">報到管理</span>
          <h1 className="text-2xl font-semibold text-gray-800">報到列表</h1>
        </div>
      </div>
      <div >
        <div className="mb-6 flex flex-col justify-between items-center md:flex-row">
          <div className="flex flex-col md:flex-row">
            <input
              type="text"
              placeholder="以姓名/Email進行查詢"
              className="pl-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 mb-2 md:mb-0 md:mr-2"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <button
              onClick={handleSearch}
              className="btn-search text-white px-2 py-1 rounded-md transition duration-300 flex items-center mb-2 md:mb-0"
            >
              <FaSearch className="mr-2" />
              查詢
            </button>
          </div>
          <div className="flex flex-col md:flex-row mr-4">
            {isNotifying ? (
              <>
                <button
                  onClick={handleMailNotify}
                  className="btn-new hover:btn-new px-2 py-1 rounded-md transition duration-300 flex items-center mb-2 md:mb-0"
                >
                  寄出
                </button>
                <button
                  onClick={handleCancelNotify}
                  className="btn-return hover:btn-return ml-2 px-2 py-1 rounded-md transition duration-300"
                >
                  取消
                </button>
              </>
            ) : (
              <div className="flex flex-col md:flex-row">
                {isGeneratingHTML ? (
                  <>
                    <button
                      onClick={generatingHTMLAPI}
                      className="btn-new hover:btn-new px-2 py-1 rounded-md transition duration-300 flex items-center mb-2 md:mb-0"
                    >
                      產出HTML
                    </button>
                    <button
                      onClick={handleCancelHTMLGeneration}
                      className="btn-return hover:btn-return ml-2 px-2 py-1 rounded-md transition duration-300"
                    >
                      取消
                    </button>
                    <span className="text-green-500"></span>
                  </>
                ) : (
                  <div className="flex flex-col md:flex-row">
                    <button
                      onClick={handleNotifyClick}
                      className="btn-new hover:btn-new px-2 py-1 rounded-md transition duration-300 flex items-center mb-2 md:mb-0 md:mr-2"
                    >
                      補報到資料通知
                    </button>
                    <button
                      onClick={generateSelfIntroductionHTML}
                      className="btn-new hover:btn-new px-2 py-1 rounded-md transition duration-300 flex items-center mb-2 md:mb-0"
                    >
                      產出自我介紹HTML
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="w-full border-collapse">
            <thead>
              <tr className="bg-gray-100 text-center select-none">
                {(isNotifying || isGeneratingHTML) && (
                  <th>
                  </th>
                )}
                <th className="p-3 font-semibold text-sm text-gray-600 cursor-pointer text-center">
                  <div className="flex items-center justify-center" onClick={() => requestSort('name')}>
                    {sortConfig.key === 'name' && sortConfig.direction === 'asc' ? (
                      <FaSortUp className="mr-1" />
                    ) : sortConfig.key === 'name' && sortConfig.direction === 'desc' ? (
                      <FaSortDown className="mr-1" />
                    ) : null} {/* 當為預設狀態時不顯示圖示 */}
                    姓名
                  </div>
                </th>
                <th className="p-3 font-semibold text-sm text-gray-600 cursor-pointer text-center">
                  <div className="flex items-center justify-center" onClick={() => requestSort('email')}>
                    {sortConfig.key === 'email' && sortConfig.direction === 'asc' ? (
                      <FaSortUp className="mr-1" />
                    ) : sortConfig.key === 'email' && sortConfig.direction === 'desc' ? (
                      <FaSortDown className="mr-1" />
                    ) : null} {/* 當為預設狀態時不顯示圖示 */}
                    Email
                  </div>
                </th>
                <th className="p-3 font-semibold text-sm text-gray-600 cursor-pointer text-center">
                  <div className="flex items-center justify-center" onClick={() => requestSort('appliedCompany')}>
                    {sortConfig.key === 'appliedCompany' && sortConfig.direction === 'asc' ? (
                      <FaSortUp className="mr-1" />
                    ) : sortConfig.key === 'appliedCompany' && sortConfig.direction === 'desc' ? (
                      <FaSortDown className="mr-1" />
                    ) : null} {/* 當為預設狀態時不顯示圖示 */}
                    應徵公司
                  </div>
                </th>
                <th className="p-3 font-semibold text-sm text-gray-600 cursor-pointer text-center">
                  <div className="flex items-center justify-center" onClick={() => requestSort('appliedDepartment')}>
                    {sortConfig.key === 'appliedDepartment' && sortConfig.direction === 'asc' ? (
                        <FaSortUp className="mr-1" />
                      ) : sortConfig.key === 'appliedDepartment' && sortConfig.direction === 'desc' ? (
                        <FaSortDown className="mr-1" />
                      ) : null} {/* 當為預設狀態時不顯示圖示 */}
                    應徵部門
                  </div>
                </th>
                <th className="p-3 font-semibold text-sm text-gray-600 cursor-pointer text-center">
                  <div className="flex items-center justify-center" onClick={() => requestSort('appliedPosition')}>
                    {sortConfig.key === 'appliedPosition' && sortConfig.direction === 'asc' ? (
                        <FaSortUp className="mr-1" />
                      ) : sortConfig.key === 'appliedPosition' && sortConfig.direction === 'desc' ? (
                        <FaSortDown className="mr-1" />
                      ) : null} {/* 當為預設狀態時不顯示圖示 */}
                    應徵職務
                  </div>
                </th>
                <th className="p-3 font-semibold text-sm text-gray-600 cursor-pointer text-center">
                  <div className="flex items-center justify-center" onClick={() => requestSort('onboardTime')}>
                    {sortConfig.key === 'onboardTime' && sortConfig.direction === 'asc' ? (
                        <FaSortUp className="mr-1" />
                      ) : sortConfig.key === 'onboardTime' && sortConfig.direction === 'desc' ? (
                        <FaSortDown className="mr-1" />
                      ) : null} {/* 當為預設狀態時不顯示圖示 */}
                    報到時間
                  </div>
                </th>
                <th className="p-3 font-semibold text-sm text-gray-600">管理</th>
              </tr>
            </thead>
            <tbody>
              {sortedCompanies().map((company, index) => (
                <tr key={company.onBoardId || index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                  {(isNotifying || isGeneratingHTML) && (
                    <td className="p-3 text-sm text-gray-700">
                      <input type="checkbox" 
                        checked={selectedRows.includes(company)}
                        onChange={() => toggleSelect(company)}
                        className="checkbox mr-1" />
                    </td>
                  )}
                  <td className="p-3 text-sm text-gray-700 text-center">{company.name}</td>
                  <td className="p-3 text-sm text-gray-700 text-center">{company.corporateEmail}</td>
                  <td className="p-3 text-sm text-gray-700 text-center">{company.corporationName}</td>
                  <td className="p-3 text-sm text-gray-700 text-center">{company.departmentName}</td>
                  <td className="p-3 text-sm text-gray-700 text-center">{company.jobTitle}</td>
                  <td className="p-3 text-sm text-gray-700 text-center">{company.onBoardDate}</td>
                  <td className="p-3 text-sm text-center">
                    <div>
                      <Link to={`/onboards/onboard-schedule/${company.onBoardId}`} className="text-blue-500 mr-2 hover:underline">報到進度</Link>
                    </div>
                    <div>
                      <Link to={`/onboards/doc-upload-schedule/${company.onBoardId}`} className="text-blue-500 mr-2 hover:underline">文件上傳進度</Link>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {totalPages > 1 && (
          <div className="mt-6 flex justify-center items-center">
            <button
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
              className="mx-1 px-3 py-1 border rounded text-gray-600 disabled:opacity-50 hover:bg-gray-100 transition duration-300"
            >
              ←
            </button>
            {renderPaginationButtons()}
            <button
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="mx-1 px-3 py-1 border rounded text-gray-600 disabled:opacity-50 hover:bg-gray-100 transition duration-300"
            >
              →
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Onboards;
