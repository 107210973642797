import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FiEdit2 } from 'react-icons/fi';
import { RiDeleteBinLine } from "react-icons/ri";
import { FaRegSave } from "react-icons/fa";
import { AiOutlineRollback } from "react-icons/ai";

function SkillsForm() {
  const [newLanguage, setNewLanguage] = useState('');
  const [editLanguage, setEditLanguage] = useState('');
  const [formData, setFormData] = useState({
    memo: '',
  });
  const [languages, setLanguages] = useState([{
    language: '英文', 
    listen: '優', 
    speak: '優', 
    read: '好', 
    write: '平'}]);
  const id = useLocation().search.split('interviewId=')[1];
  const navigate = useNavigate();
  const [editIndex, setEditIndex] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('保存的數據:', formData);
    navigate(`/application-info/other-info?interviewId=${id}`);
  }

  const handleAdd = () => {
    if (newLanguage.language && newLanguage.listen && newLanguage.speak && newLanguage.read && newLanguage.write) {
      setNewLanguage([...languages, setLanguages]);
    }
  };

  const handleEditSave = () => {
    if (editIndex !== null) {
      const updatedLanguages = [...languages];
      updatedLanguages[editIndex] = editLanguage;
      setEditLanguage([...languages, updatedLanguages]);
      setEditIndex(null);
    } else {
      setEditLanguage([...languages, editLanguage]);
    }
    setEditLanguage('');
  };

  const handleAddCancel = () => {
    setEditIndex(null);
  };

  const handleEdit = (index) => {
    setEditLanguage(languages[index]);
    setEditIndex(index);
  };

  const handleDelete = (index) => {
    if (window.confirm('您確定要刪除這個語文能力嗎？')) {
      const updatedLanguages = languages.filter((_, i) => i !== index);
      setEditLanguage(updatedLanguages);
    }
  };

  return (
    <div id="application-info" className="max-w-screen-2xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <div>
          <span className="text-gray-400">應徵者輸入應徵資料</span>
          <h1 className="text-2xl font-semibold text-gray-800">專長/技能</h1>
        </div>
      </div>
      <div className="bg-white shadow-sm rounded-lg p-6 max-w-6xl">
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">專長/技能 – 語文能力</label>
            <div className="overflow-x-auto">
              <table className="w-full border-collapse border border-gray-300">
                <thead className="bg-gray-50">
                  <tr className="text-center">
                    <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider">語文</th>
                    <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider">聽</th>
                    <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider">說</th>
                    <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider">讀</th>
                    <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider">寫</th>
                    <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider">編輯</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  <tr>
                    <td className="px-4 py-2">
                      <select
                        className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        value={newLanguage.language}
                        onChange={(e) => setNewLanguage(e.target.value)}
                      >
                        <option value="">請選擇</option>
                        <option value="中文">中文</option>
                        <option value="英文">英文</option>
                        <option value="日文">日文</option>
                        <option value="台語">台語</option>
                      </select>
                    </td>
                    <td className="px-4 py-2">
                      <select
                        className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        value={newLanguage.listen}
                        onChange={(e) => setNewLanguage(e.target.value)}
                      >
                        <option value="">請選擇</option>
                        <option value="優">優</option>
                        <option value="好">好</option>
                        <option value="平">平</option>
                      </select>
                    </td>
                    <td className="px-4 py-2">
                      <select
                        className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        value={newLanguage.speak}
                        onChange={(e) => setNewLanguage(e.target.value)}
                      >
                        <option value="">請選擇</option>
                        <option value="優">優</option>
                        <option value="好">好</option>
                        <option value="平">平</option>
                      </select>
                    </td>
                    <td className="px-4 py-2">
                      <select
                        className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        value={newLanguage.read}
                        onChange={(e) => setNewLanguage(e.target.value)}
                      >
                        <option value="">請選擇</option>
                        <option value="優">優</option>
                        <option value="好">好</option>
                        <option value="平">平</option>
                      </select>
                    </td>
                    <td className="px-4 py-2">
                      <select
                        className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        value={newLanguage.write}
                        onChange={(e) => setNewLanguage(e.target.value)}
                      >
                        <option value="">請選擇</option>
                        <option value="優">優</option>
                        <option value="好">好</option>
                        <option value="平">平</option>
                      </select>
                    </td>
                    <td className="px-4 py-2 text-center">
                      <button
                        onClick={handleAdd}
                        className="btn-new hover:btn-new px-3 py-1 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                      >
                        新增
                      </button>
                    </td>
                  </tr>
                  {languages.map((language, index) => (
                    <tr key={index}>
                      <td className="px-4 py-2">
                        {editIndex === index ? (
                          <select
                            className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                            value={editLanguage.language}
                            onChange={(e) => setEditLanguage(e.target.value)}
                          >
                            <option value="">請選擇</option>
                            <option value="中文">中文</option>
                            <option value="英文">英文</option>
                            <option value="日文">日文</option>
                            <option value="台語">台語</option>
                          </select>
                        ) : (
                          language.language
                        )}
                      </td>
                      <td className="px-4 py-2">
                        {editIndex === index ? (
                          <select
                            className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                            value={editLanguage.listen}
                            onChange={(e) => setEditLanguage(e.target.value)}
                          >
                            <option value="">請選擇</option>
                            <option value="優">優</option>
                            <option value="好">好</option>
                            <option value="平">平</option>
                          </select>
                        ) : (
                          language.listen
                        )}
                      </td>
                      <td className="px-4 py-2">
                        {editIndex === index ? (
                          <select
                            className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                            value={editLanguage.speak}
                            onChange={(e) => setEditLanguage(e.target.value)}
                          >
                            <option value="">請選擇</option>
                            <option value="優">優</option>
                            <option value="好">好</option>
                            <option value="平">平</option>
                          </select>
                        ) : (
                          language.listen
                        )}
                      </td>
                      <td className="px-4 py-2">
                        {editIndex === index ? (
                          <select
                            className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                            value={editLanguage.read}
                            onChange={(e) => setEditLanguage(e.target.value)}
                          >
                            <option value="">請選擇</option>
                            <option value="優">優</option>
                            <option value="好">好</option>
                            <option value="平">平</option>
                          </select>
                        ) : (
                          language.listen
                        )}
                      </td>
                      <td className="px-4 py-2">
                        {editIndex === index ? (
                          <select
                            className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                            value={editLanguage.write}
                            onChange={(e) => setEditLanguage(e.target.value)}
                          >
                            <option value="">請選擇</option>
                            <option value="優">優</option>
                            <option value="好">好</option>
                            <option value="平">平</option>
                          </select>
                        ) : (
                          language.listen
                        )}
                      </td>
                      <td className="px-4 py-2 text-center whitespace-nowrap">
                        {editIndex === index ? (
                          <div>
                            <button
                              onClick={handleEditSave}
                              className="px-2 py-2 rounded-md btn-icon"
                            >
                              <FaRegSave />
                            </button>
                            <button
                              onClick={handleAddCancel}
                              className="ml-2 px-2 py-2 rounded-md btn-icon"
                            >
                              <AiOutlineRollback />
                            </button>
                          </div>
                        ) : (
                          <div>
                            <button
                              onClick={() => handleEdit(index)}
                              className="px-2 py-2 rounded-md btn-icon"
                            >
                              <FiEdit2 />
                            </button>
                            <button
                              onClick={() => handleDelete(index)}
                              className="ml-2 px-2 py-2 rounded-md btn-icon"
                            >
                              <RiDeleteBinLine />
                            </button>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">專長/技能 – 打字速度</label>
            <div className='flex'>
              <div className='w-full'>
                <div>
                  中文 輸入法：
                  <input
                    type="text"
                    name="chineseTypingKeyboard"
                    className="ml-1 mr-3 w-24 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    value={formData.chineseTypingSpeed}
                  />
                  每分鐘：
                  <input
                    type="text"
                    name="chineseTypingPerMinute"
                    className="ml-1 mr-3 w-12 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    value={formData.chineseTypingSpeed}
                  />
                  字
                </div>
              </div>
              <div className='w-full'>
                <div>
                  英文 每分鐘：
                  <input
                    type="text"
                    name="englishTypingPerMinute"
                    className="ml-1 mr-3 w-12 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    value={formData.chineseTypingSpeed}
                  />
                  字
                </div>
              </div>
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">專長/技能 –電腦證照/英文檢定證照</label>
            <textarea
              type="text"
              name="name"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              rows="3"
              style={{ minHeight: '100px' }}
            />
          </div>
          <div className="flex justify-center space-x-2">
            <button type="reset" className="px-4 py-2 border rounded-md text-gray-600 hover:bg-gray-100">
              清除
            </button>
            <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
              儲存
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SkillsForm;
