import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getById, putById } from '../../services/api';

function Meeting() {
  const [profileData, setProfileData] = useState({
    mail: 'wangsmallming@gmail.com',
    name: '王小明',
  });
  const [meetingData, setMeetingData] = useState({
    company: '零壹科技',
    department: '資訊部門',
    jobTitle: '軟體工程師',
    jobCategory: '軟體工程師',
    source: '104人力銀行',
    resumeDate: '2024-01-01',
    resumeDateEnd: '2024-01-01',
  });
  const [interviewSessionData, setInterviewSessionData] = useState({
    id: '',
    notificationMessage: '',
    interviewStages: [],
  });
  const [newLocation, setNewLocation] = useState({ city: '', address: '' });
  const [editingIndex, setEditingIndex] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await getById('/api/ehrm/v1/Interview/InterviewSession', id);
      setInterviewSessionData(response);
    } catch (error) {
      setError('獲取數據失敗');
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div>加載中...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="mx-auto">
      <div className="flex justify-between items-center mb-6">
        <div>
          <span className="text-gray-400">招募管理</span>
          <h1 className="text-2xl font-semibold text-gray-800">檢視面談資料</h1>
        </div>
      </div>
      <div className="bg-white shadow-sm rounded-lg p-6 max-w-6xl">
          <div>
            <h3 className="text-lg font-semibold text-gray-800 mb-4">基本資料</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Mail</label>
                {profileData.mail}
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">姓名</label>
                {profileData.name}
              </div>
            </div>
          </div>

          <div className="mt-4">
            <h3 className="text-lg font-semibold text-gray-800 mb-4">面談資料</h3>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-500 mb-1">公司</label>
                {meetingData.company}
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-500 mb-1">部門</label>
                {meetingData.department}
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-500 mb-1">應徵職務</label>
                {meetingData.jobTitle}
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-500 mb-1">職務類別</label>
                {meetingData.jobCategory}
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 py-3">
              <div>
                <label className="block text-sm font-medium text-gray-500 mb-1">履歷可填寫日期</label>
                <div className="flex space-x-4">
                  {meetingData.resumeDate}
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-500 mb-1">應徵來源</label>
                <div className="flex space-x-4">
                  {meetingData.source}
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-500 mb-1">履歷可填寫結束日期</label>
                <div className="flex space-x-4">
                  {meetingData.resumeDateEnd}
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="bg-gray-50 p-4 rounded-md">
              <table className="w-full">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="p-2 text-left font-medium text-gray-700 w-1/4">初/複試</th>
                    <th className="p-2 text-left font-medium text-gray-700 w-1/4">主管姓名</th>
                    <th className="p-2 text-left font-medium text-gray-700 w-1/4">面談時間</th>
                  </tr>
                </thead>
                <tbody>
                  {interviewSessionData.interviewStages.map((data, index) => (
                    <tr key={index} className="border-b border-gray-200">
                      <td className="p-2">
                        {data.type === '0' ? 'HR' : data.type === '1' ? '初' : '複'}
                      </td>
                      <td className="p-2">
                        {data.interviewerName}
                      </td>
                      <td className="p-2">
                        {data.time}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="mb-4">
            <div className="flex justify-between items-center">
              <h3 className="text-lg font-semibold text-gray-800 mb-4">通知訊息</h3>
            </div>
          </div>
          <div className="mb-4">
            {interviewSessionData.notificationMessage}
          </div>

          <div className="flex justify-center space-x-3">
            <button type="button" onClick={() => navigate('/meetings?interviewId=' + id)} className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-100 transition duration-300">
              返回
            </button>
          </div>
      </div>
    </div>
  );
}

export default Meeting;
