import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaSearch, FaUndo } from 'react-icons/fa';
import { get, create, update, deleteById } from '../../services/api';

const API_URL = process.env.REACT_APP_API_URL;

function Interviews() {
  const [interviews, setInterviews] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [interviewsPerPage] = useState(25);
  const [totalInterviews, setTotalInterviews] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const fetchInterviews = useCallback(async (search, page) => {
    setLoading(true);
    setError(null);
    try {
      const response = await get('/api/ehrm/v1/Interviews', {});
      console.log('API 完整響應:', response);

      if (response && Array.isArray(response)) {
        setInterviews(response);
        setTotalInterviews(response.length);
      } else {
        console.error('API 響應結構不符合預期:', response);
        setInterviews([]);
        setTotalInterviews(0);
        setError('獲取面試數據時出現問題');
      }
    } catch (error) {
      console.error('獲取面試列表失敗:', error);
      setError('獲取面試列表時發生錯誤');
      setInterviews([]);
      setTotalInterviews(0);
    } finally {
      setLoading(false);
    }
  }, [interviewsPerPage]);

  useEffect(() => {
    fetchInterviews(searchTerm, currentPage);
  }, [fetchInterviews, currentPage]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearch = () => {
    setCurrentPage(1);
    fetchInterviews(searchTerm, 1);
  };

  const handleReset = () => {
    setSearchTerm('');
    setCurrentPage(1);
    fetchInterviews('', 1);
  };

  const handleSearchKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSearch();
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('確定要刪除這家公司嗎？')) {
      try {
        await axios.delete(`${API_URL}/api/comm/v1/system/Corporation/${id}`);
        fetchInterviews(searchTerm, currentPage);
      } catch (error) {
        console.error('刪除面試失敗:', error);
      }
    }
  };

  const handleUpload = async (id) => {
    document.getElementById('upload-' + id).click();
  }

  const totalPages = Math.ceil(totalInterviews / interviewsPerPage);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const renderPaginationButtons = () => {
    const maxButtons = 5;
    let startPage, endPage;

    if (totalPages <= maxButtons) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= Math.ceil(maxButtons / 2)) {
        startPage = 1;
        endPage = maxButtons;
      } else if (currentPage + Math.floor(maxButtons / 2) >= totalPages) {
        startPage = totalPages - maxButtons + 1;
        endPage = totalPages;
      } else {
        startPage = currentPage - Math.floor(maxButtons / 2);
        endPage = currentPage + Math.floor(maxButtons / 2);
      }
    }

    return (
      <>
        {startPage > 1 && (
          <>
            <button onClick={() => paginate(1)} className="mx-1 px-3 py-1 border rounded">1</button>
            {startPage > 2 && <span className="mx-1">...</span>}
          </>
        )}
        {Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i).map(number => (
          <button
            key={number}
            onClick={() => paginate(number)}
            className={`mx-1 px-3 py-1 border rounded ${currentPage === number ? 'bg-blue-500 text-white' : 'bg-white'}`}
          >
            {number}
          </button>
        ))}
        {endPage < totalPages && (
          <>
            {endPage < totalPages - 1 && <span className="mx-1">...</span>}
            <button onClick={() => paginate(totalPages)} className="mx-1 px-3 py-1 border rounded">{totalPages}</button>
          </>
        )}
      </>
    );
  };

  if (loading) return <div>加載中...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="mx-auto">
      <div className="flex justify-between items-center mb-6">
        <div>
          <span className="text-gray-400">招募管理</span>
          <h1 className="text-2xl font-semibold text-gray-800">面試列表</h1>
        </div>
      </div>
      <div>
        <div className="mb-6 flex justify-between items-center  flex-col md:flex-row">
          <div className="flex">
            <input
              type="text"
              placeholder="以姓名/Email進行查詢"
              className="pl-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 mb-2 mr-2 md:mb-0 md:mr-2"
              value={searchTerm}
              onChange={handleSearchChange}
              onKeyDown={handleSearchKeyDown}
            />
            <button
              onClick={handleSearch}
              className="px-2 py-2 rounded-md btn-search hover:btn-search flex items-center text-white"
            >
              <FaSearch className="mr-2" />
              查詢
            </button>
          </div>
          <Link to="/interviews/new" className="px-2 py-1 btn-new hover:btn-new border rounded-md transition duration-300">
            新增面試
          </Link>
        </div>

        <div className="overflow-x-auto">
          <table className="w-full border-collapse">
            <thead>
              <tr className="bg-gray-100 text-center">
                <th className="p-3 font-semibold text-sm text-gray-600">姓名</th>
                <th className="p-3 font-semibold text-sm text-gray-600">Email</th>
                <th className="p-3 font-semibold text-sm text-gray-600">應徵公司</th>
                <th className="p-3 font-semibold text-sm text-gray-600">應徵部門</th>
                <th className="p-3 font-semibold text-sm text-gray-600">應徵職務</th>
                <th className="p-3 font-semibold text-sm text-gray-600">填寫完成時間</th>
                <th className="p-3 font-semibold text-sm text-gray-600">管理</th>
              </tr>
            </thead>
            <tbody>
              {interviews.map((interview, index) => (
                <tr key={interview.id} className={index % 2 === 0 ? 'bg-white text-center' : 'bg-gray-50 text-center'}>
                  <td className="p-3 text-sm text-gray-700">
                    <Link to={`/application-info?interviewId=${interview.id}`} className="text-blue-500 hover:underline">
                      {interview.name}
                    </Link>
                  </td>
                  <td className="p-3 text-sm text-gray-700">{interview.email}</td>
                  <td className="p-3 text-sm text-gray-700">{interview.applicantCompany}</td>
                  <td className="p-3 text-sm text-gray-700">{interview.applicantDepartment}</td>
                  <td className="p-3 text-sm text-gray-700">{interview.applicantPosition}</td>
                  <td className="p-3 text-sm text-gray-700">{interview.createdTime}</td>
                  <td className="p-3 text-sm">
                    <div>
                      <a href={`/meetings?interviewId=${interview.id}`} className="text-blue-500 mr-2 hover:underline">
                        面談
                      </a>
                      <a href={`/interviews/edit/${interview.id}`} className="text-blue-500 mr-2 hover:underline">
                        編輯
                      </a>
                      <a href={`/interviews/upload/${interview.id}`} className="text-blue-500 mr-2 hover:underline">
                        檔案上傳
                      </a>
                    </div>
                    <div>
                      <a href={`/interviews/reference-checks/${interview.id}`} className="text-blue-500 mr-2 hover:underline">
                        資歷查核
                      </a>
                      <a href={`/interviews/result/${interview.id}`} className="text-blue-500 mr-2 hover:underline">
                        面試結果
                      </a>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {totalPages > 1 && (
          <div className="mt-6 flex justify-center items-center">
            <button
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
              className="mx-1 px-3 py-1 border rounded text-gray-600 disabled:opacity-50 hover:bg-gray-100 transition duration-300"
            >
              ←
            </button>
            {renderPaginationButtons()}
            <button
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="mx-1 px-3 py-1 border rounded text-gray-600 disabled:opacity-50 hover:bg-gray-100 transition duration-300"
            >
              →
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Interviews;
