import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaSearch, FaUndo, FaFileExcel, FaFilePdf, FaSortUp, FaSortDown } from 'react-icons/fa';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const API_URL = process.env.REACT_APP_API_URL;

function Recruiter() {
  
  return (
    <div className="mx-auto">
      <div className="flex justify-between items-center mb-6">
        <div>
          <span className="text-gray-400"></span>
          <h1 className="text-2xl font-semibold text-gray-800">錄取管理</h1>
        </div>
      </div>
      <div >
        <div className='flex'>
          <div className='flex-1'>
            <label className="text-gray-400">姓名</label>
            <div>王小明</div>
          </div>
          <div className='flex-1'>
            <label className="text-gray-400">Email</label>
            <div>test@gmail.com</div>
          </div>
          <div className='flex-1'>
            <label className="text-gray-400">應徵公司</label>
            <div>零壹科技</div>
          </div>
          <div className='flex-1'>
            <label className="text-gray-400">應徵部門</label>
            <div>資訊中心</div>
          </div>
          <div className='flex-1'>
            <label className="text-gray-400">應徵職務</label>
            <div>工程師</div>
            </div>
          </div>
        </div>
      </div>
  );
}

export default Recruiter;
