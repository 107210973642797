import React, { useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';

function OnboardSchedule() {
  const [formData, setFormData] = useState({
    memo: '',
  });
  const navigate = useNavigate();
  const id = useLocation().search.split('interviewId=')[1];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('保存的數據:', formData);
    navigate(`/interviews`);
  }

  return (
    <div id="application-info" className="max-w-screen-2xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <div>
          <span className="text-gray-400">報到管理</span>
          <h1 className="text-2xl font-semibold text-gray-800">報到進度</h1>
        </div>
      </div>
      <div className="bg-white shadow-sm rounded-lg p-6 max-w-6xl">
        <table className="w-full border-collapse border border-gray-300 mt-4">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">簽署文件</th>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">簽署時間</th>
            </tr>
          </thead>   
          <tbody>
            <tr>
              <td className="border px-4 py-2">服務同意書</td>
              <td className="border px-4 py-2">2024-09-06</td>
            </tr>
            <tr>
              <td className="border px-4 py-2">個資同意書</td>
              <td className="border px-4 py-2">2024-09-06</td>
            </tr>
            <tr>
              <td className="border px-4 py-2">員工電腦軟體使用自律同意書</td>
              <td className="border px-4 py-2">2024-09-06</td>
            </tr>
            <tr>
              <td className="border px-4 py-2">體（健）檢資料蒐集同意書</td>
              <td className="border px-4 py-2">2024-09-06</td>
            </tr>
            <tr>
              <td className="border px-4 py-2">台灣大車隊-個資同意書</td>
              <td className="border px-4 py-2"></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="flex justify-center space-x-2 mt-4">
        <button type="button" onClick={() => navigate(`/onboards`)} className="px-4 py-2 border rounded-md text-white bg-blue-500 hover:bg-blue-600">
          已報到
        </button>
        <button type="button" onClick={() => navigate(`/onboards`)} className="px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300">
          返回
        </button>
      </div>
    </div>
  );
}

export default OnboardSchedule;
