import React, { useState, useEffect } from 'react';
import { getApplicantSources, createApplicantSource, updateApplicantSource, deleteApplicantSource } from '../../services/api';
import { FiEdit2 } from 'react-icons/fi';
import { RiDeleteBinLine } from "react-icons/ri";
import { FaRegSave } from "react-icons/fa";
import { AiOutlineRollback } from "react-icons/ai";

function ApplicantSources() {
  const [newSource, setNewSource] = useState({ name: '' });
  const [editSource, setEditSource] = useState({ name: '' });
  const [sources, setSources] = useState([]);
  const [editIndex, setEditIndex] = useState(null);

  useEffect(() => {
    async function fetchApplicantSources() {
      const response = await getApplicantSources();
      setSources(response.items);
    }
    fetchApplicantSources();
  }, []);

  const handleAdd = () => {
    if (newSource.name !== '') {
      setSources([...sources, newSource]);
      setNewSource({ name: '' });
      createApplicantSource(newSource);
    }
  };

  const handleEditSave = () => {
    if (editIndex !== null) {
      const updatedSources = [...sources];
      updatedSources[editIndex] = editSource;
      setSources(updatedSources);
      setEditIndex(null);
      updateApplicantSource(editSource.id, editSource);
    } else {
      setSources([...sources, editSource]);
    }
    setEditSource('');
  };

  const handleAddCancel = () => {
    setNewSource('');
    setEditIndex(null);
  };

  const handleEdit = (index) => {
    setEditSource(sources[index]);
    setEditIndex(index);
  };

  const handleDelete = (index) => {
    if (window.confirm('您確定要刪除這個來源嗎？')) {
      const updatedSources = sources.filter((_, i) => i !== index);
      setSources(updatedSources);
      deleteApplicantSource(sources[index].id);
    }
  };

  return (
    <div className="mx-auto">
      <div className="flex justify-between items-center mb-6">
        <div>
          <span className="text-gray-400">系統管理</span>
          <h1 className="text-2xl font-semibold text-gray-800">面試來源</h1>
        </div>
      </div>
      <div>
        <div className="overflow-x-auto">
          <table className="w-full border-collapse border border-gray-300">
            <thead className="bg-gray-50">
              <tr className="text-center">
                <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider">面試來源</th>
                <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider">管理</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              <tr>
                <td className="px-4 py-2">
                  <input
                    type="text"
                    className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={newSource.name}
                    onChange={(e) => setNewSource({ ...newSource, name: e.target.value })}
                    placeholder="輸入新的面試來源"
                  />
                </td>
                <td className="px-4 py-2 text-center">
                  <button
                    onClick={handleAdd}
                    className="btn-new hover:btn-new px-3 py-1 bg-blue-500 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  >
                    新增
                  </button>
                </td>
              </tr>
              {sources.map((source, index) => (
                <tr key={index}>
                  <td className="px-4 py-2">
                    {editIndex === index ? (
                      <input
                        type="text"
                        className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        value={editSource.name}
                        onChange={(e) => setEditSource({ ...editSource, name: e.target.value })}
                      />
                    ) : (
                      source.name
                    )}
                  </td>
                  <td className="px-4 py-2 text-center">
                    {editIndex === index ? (
                      <div>
                        <button
                          onClick={handleEditSave}
                          className="px-2 py-1 rounded-md btn-icon"
                        >
                          <FaRegSave />
                        </button>
                        <button
                          onClick={handleAddCancel}
                          className="ml-2 px-2 py-1 rounded-md btn-icon"
                        >
                          <AiOutlineRollback />
                        </button>
                      </div>
                    ) : (
                      <div>
                        <button
                          onClick={() => handleEdit(index)}
                          className="px-2 py-1 rounded-md btn-icon"
                        >
                          <FiEdit2 />
                        </button>
                        <button
                          onClick={() => handleDelete(index)}
                          className="px-2 py-1 rounded-md btn-icon ml-2"
                        >
                          <RiDeleteBinLine />
                        </button>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ApplicantSources;
