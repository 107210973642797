import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiEdit2 } from 'react-icons/fi';
import { RiDeleteBinLine } from "react-icons/ri";
import { FaRegSave } from "react-icons/fa";
import { AiOutlineRollback } from "react-icons/ai"; 

function EngineerCertificateDetails() {
  const [newDetail, setNewDetail] = useState({
    'certificateName' : '',
    'certificateNumber' : '',
    'issuingAgency' : '',
    'effectiveDate' : '',
    'expirationDate' : '',
    'note' : '',
  });
  const [editDetail, setEditDetail] = useState({
    'certificateName' : '',
    'certificateNumber' : '',
    'issuingAgency' : '',
    'effectiveDate' : '',
    'expirationDate' : '',
    'note' : '',
  });
  const [details, setDetails] = useState([{
    'certificateName' : 'xxxxx',
    'certificateNumber' : '1456789',
    'issuingAgency' : 'xxxxxxxx',
    'effectiveDate' : '2023-01-02',
    'expirationDate' : '2030-12-30',
    'note' : ''
  }]);
  const [editIndex, setEditIndex] = useState(null);
  const navigate = useNavigate();

  const handleAdd = () => {
    if (newDetail) {
      setDetails([...details, newDetail]);
      setNewDetail('');
    }
  };

  const handleEditSave = () => {
    if (editIndex !== null) {
      const updatedDetails = [...details];
      updatedDetails[editIndex] = editDetail;
      setDetails(updatedDetails);
      setEditIndex(null);
    } else {
      setDetails([...details, editDetail]);
    }
    setEditDetail('');
  };

  const handleAddCancel = () => {
    setNewDetail('');
    setEditIndex(null);
  };

  const handleEdit = (index) => {
    setEditDetail(details[index]);
    setEditIndex(index);
  };

  const handleDelete = (index) => {
    if (window.confirm('您確定要刪除這個明細嗎？')) {
      const updatedDetails = details.filter((_, i) => i !== index);
      setDetails(updatedDetails);
    }
  };

  return (
    <div className="mx-auto">
      <div className="flex justify-between items-center mb-6">
        <div>
          <span className="text-gray-400"></span>
          <h1 className="text-2xl font-semibold text-gray-800">工程師證照明細</h1>
        </div>
      </div>
      <div className="bg-white shadow rounded-lg p-6">
        <div className="overflow-x-auto">
          <table className="w-full border-collapse border border-gray-300">
            <thead className="bg-gray-50">
              <tr className='text-center'>
                <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider">證照名稱 <br/>(請填寫完整名稱)</th>
                <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider">證照號碼</th>
                <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider">發證機構</th>
                <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider">生效日期</th>
                <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider">失效日期</th>
                <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider">備註</th>
                <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider">管理</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              <tr className="text-center">
                <td className="px-4 py-2">
                  <input
                    type="text"
                    className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={newDetail.certificateName}
                    onChange={(e) => setNewDetail({...newDetail, certificateName: e.target.value})}
                  />
                </td>
                <td className="px-4 py-2">
                  <input
                    type="text"
                    className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={newDetail.certificateNumber}
                    onChange={(e) => setNewDetail({...newDetail, certificateNumber: e.target.value})}
                  />
                </td>
                <td className="px-4 py-2">
                  <input
                    type="text"
                    className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={newDetail.issuingAgency}
                    onChange={(e) => setNewDetail({...newDetail, issuingAgency: e.target.value})}
                  />
                </td>
                <td className="px-4 py-2">
                  <input
                    type="text"
                    className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={newDetail.effectiveDate}
                    onChange={(e) => setNewDetail({...newDetail, effectiveDate: e.target.value})}
                  />
                </td>
                <td className="px-4 py-2">
                  <input
                    type="text"
                    className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={newDetail.expirationDate}
                    onChange={(e) => setNewDetail({...newDetail, expirationDate: e.target.value})}
                  />
                </td>
                <td className="px-4 py-2">
                  <input
                    type="text"
                    className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={newDetail.note}
                    onChange={(e) => setNewDetail({...newDetail, note: e.target.value})}
                  />
                </td>
                <td className="px-4 py-2">
                  <button
                    onClick={handleAdd}
                    className="btn-new hover:btn-new px-3 py-1 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  >
                    新增
                  </button>
                </td>
              </tr>
              {details.map((detail, index) => (
                <tr key={index}>
                  <td className="px-4 py-2">
                    {editIndex === index ? (
                      <input
                        type="text"
                        className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        value={editDetail.certificateName}
                        onChange={(e) => setEditDetail({...editDetail, certificateName: e.target.value})}
                      />
                    ) : (
                      detail.certificateName
                    )}
                  </td>
                  <td className="px-4 py-2">
                    {editIndex === index ? (
                      <input
                        type="text"
                        className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        value={editDetail.certificateNumber}
                        onChange={(e) => setEditDetail({...editDetail, certificateNumber: e.target.value})}
                      />
                    ) : (
                      detail.certificateNumber
                    )}
                  </td>
                  <td className="px-4 py-2">
                    {editIndex === index ? (
                      <input
                        type="text"
                        className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        value={editDetail.issuingAgency}
                        onChange={(e) => setEditDetail({...editDetail, issuingAgency: e.target.value})}
                      />
                    ) : (
                      detail.issuingAgency
                    )}
                  </td>
                  <td className="px-4 py-2">
                    {editIndex === index ? (
                      <input
                        type="text"
                        className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        value={editDetail.effectiveDate}
                        onChange={(e) => setEditDetail({...editDetail, effectiveDate: e.target.value})}
                      />
                    ) : (
                      detail.effectiveDate
                    )}
                  </td>
                  <td className="px-4 py-2">
                    {editIndex === index ? (
                      <input
                        type="text"
                        className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        value={editDetail.expirationDate}
                        onChange={(e) => setEditDetail({...editDetail, expirationDate: e.target.value})}
                      />
                    ) : (
                      detail.expirationDate
                    )}
                  </td>
                  <td className="px-4 py-2">
                    {editIndex === index ? (
                      <input
                        type="text"
                        className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        value={editDetail.note}
                        onChange={(e) => setEditDetail({...editDetail, note: e.target.value})}
                      />
                    ) : (
                      detail.note
                    )}
                  </td>
                  <td className="px-4 py-2 whitespace-nowrap">
                    {editIndex === index ? (
                      <div>
                        <button
                          onClick={handleEditSave}
                          className="px-2 py-2 rounded-md btn-icon"
                        >
                          <FaRegSave />
                        </button>
                        <button
                          onClick={handleAddCancel}
                          className="ml-2 px-2 py-2 rounded-md btn-icon"
                        >
                          <AiOutlineRollback />
                        </button>
                      </div>
                    ) : (
                      <div>
                        <button
                          onClick={() => handleEdit(index)}
                          className="px-2 py-2 rounded-md btn-icon"
                        >
                          <FiEdit2 />
                        </button>
                        <button
                          onClick={() => handleDelete(index)}
                          className="px-2 py-2 rounded-md btn-icon ml-2"
                        >
                          <RiDeleteBinLine />
                        </button>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="text-right mt-4">
            <button
              onClick={() => navigate('/recruiter')}
              className="btn-save hover:btn-save px-3 py-1 text-white rounded-md"
            >
              確認
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EngineerCertificateDetails;
