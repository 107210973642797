import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TiDelete } from 'react-icons/ti';

function PhysicalExaminationReport() {
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);
  const navigate = useNavigate();
  const FileUpload1 = (e) => {
    e.target.files[0] && setFile1(URL.createObjectURL(e.target.files[0]));
  };

  const FileUpload2 = (e) => {
    e.target.files[0] && setFile2(URL.createObjectURL(e.target.files[0]));
  };

  const DeleteFile1 = () => {
    setFile1(null);
  };

  const DeleteFile2 = () => {
    setFile2(null);
  };

  return (
    <div className="flex flex-col items-center justify-center max-w-screen-2xl mx-auto">
      <div className="w-full text-left">
        <div className="flex justify-between items-center mb-6">
          <div>
            <span className="text-gray-400"></span>
            <h1 className="text-2xl font-semibold text-gray-800">新進員工體格檢查注意事項</h1>
          </div>
        </div>
      </div>
      <div className="w-full bg-white shadow-md rounded-lg p-6">
        <table>
          <tr>
            <td className="border px-4 py-2">1</td>
            <td className="border px-4 py-2 w-1/4">受檢單位</td>
            <td className="border px-4 py-2">
              認可醫療機構查詢網址：<br />
              <a href="https://hrpts.osha.gov.tw/asshp/hrpm1055.aspx" target="_blank">https://hrpts.osha.gov.tw/asshp/hrpm1055.aspx</a>
              <br />
              "進入網站後，請依序填入『縣市別=>鄉鎮市區=>健檢類別項目勾選一般健檢=>查詢目前有效認可醫療機構』到醫院請告知要做"新進員工體格檢查""
            </td>
          </tr>
          <tr>
            <td className="border px-4 py-2">2</td>
            <td className="border px-4 py-2">報告之檢查期限</td>
            <td className="border px-4 py-2">
              報到前一年內可提供前一公司健檢報告，但須為"認可醫療機構"，並且包含下列項目即可繳交
            </td>
          </tr>
          <tr>
            <td className="border px-4 py-2">3</td>
            <td className="border px-4 py-2">檢查項目</td>
            <td className="border px-4 py-2">
              <ul> 
                <li>(1)作業經歷、既往病史、生活習慣及自覺症狀之調查</li>
                <li>(2)身高、體重、腰圍、視力、辨色力、聽力、血壓與身體各系統或部位之身體檢查及問診</li>
                <li>(3)胸部Ｘ光（大片）攝影檢查</li>
                <li>(4)尿蛋白及尿潛血之檢查</li>
                <li>(5)血色素及白血球數檢查</li>
                <li>(6)血糖、血清丙胺酸轉胺酶(ALT)、肌酸酐(creatinine)、膽固醇、三酸甘油酯、高密度脂蛋白膽固醇之檢查</li>
                <li>(7)其他經中央主管機關指定之檢查</li>
              </ul>
            </td>
          </tr>
        </table>
        <div className="flex justify-center space-x-2 mt-4">
          <button type="button" onClick={() => navigate(`/recruiter/doc-upload`)} className="px-4 py-2 border rounded-md bg-blue-500 text-white rounded-md hover:bg-blue-600">
            確認上傳
          </button>
          <button type="button" onClick={() => navigate(`/recruiter/doc-upload`)} className="px-4 py-2 border rounded-md text-gray-600 bg-gray-200 hover:bg-gray-100">
            返回
          </button>
        </div>
      </div>
    </div>
  );
}

export default PhysicalExaminationReport;
