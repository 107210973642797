import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { FiEdit2 } from 'react-icons/fi';
import { RiDeleteBinLine } from "react-icons/ri";
import { FaRegSave } from "react-icons/fa";
import { AiOutlineRollback } from "react-icons/ai";

function FamilyStatus() {
  const [formData, setFormData] = useState({
    familyStatus: { name: '', relationship: '', company: '', title: '' },
  });
  const navigate = useNavigate();
  const id = useLocation().search.split('interviewId=')[1];
  const [newFamilyStatus, setNewFamilyStatus] = useState([]);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [emergencyContact, setEmergencyContact] = useState({ name: '', relationship: '', phone: '', address: '' });

  // useEffect(() => {
  //   console.log('formData:', formData);
  // }, [formData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, familyStatus: { ...formData.familyStatus, [name]: value }});
  };

  const handleNewFamilyStatusChange = (e) => {
    const { name, value } = e.target;
    setNewFamilyStatus(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('保存的數據:', formData);
    navigate(`/application-info/questions?interviewId=${id}`);
  }

  const handleAddFamilyStatus = () => {
    if (formData.familyStatus.name && formData.familyStatus.relationship && formData.familyStatus.company && formData.familyStatus.title) {
      setNewFamilyStatus([...newFamilyStatus, formData.familyStatus]);
      setFormData({
        familyStatus: { name: '', relationship: '', company: '', title: '' },
        emergencyContact: { name: '', relationship: '', phone: '', address: '' },
      });
    }
  };

  const handleFamilyStatusChange = (index, field, value) => {
    setNewFamilyStatus(prev => {
      const updatedStatuses = [...prev];
      updatedStatuses[index] = { ...updatedStatuses[index], [field]: value };
      return updatedStatuses;
    });
  };

  const handleEditFamilyStatus = (index) => {
    setEditingIndex(index);
  };

  const handleSaveFamilyStatus = () => {
    setEditingIndex(-1);
  };

  const handleDeleteFamilyStatus = (index) => {
    if (window.confirm('確定要刪除嗎？')) {
      try {
        setNewFamilyStatus(prev => prev.filter((_, i) => i !== index));
      } catch (error) {
        console.error('刪除家庭狀況失敗:', error);
      }
    }
  };

  const handleCancelFamilyStatus = () => {
    setEditingIndex(-1);
  };

  const handleInputEmergencyContactChange = (e) => {
    const { name, value } = e.target;
    setEmergencyContact(prev => ({ ...prev, [name]: value }));
  };

  return (
    <div id="application-info" className="max-w-screen-2xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <div>
          <span className="text-gray-400">應徵者輸入應徵資料</span>
          <h1 className="text-2xl font-semibold text-gray-800">面試者填寫應徵者家庭狀況</h1>
        </div>
      </div>
      <div className="bg-white shadow-sm rounded-lg p-6 max-w-6xl">
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-lg font-medium text-gray-700">家庭狀況</label>
            <table className="w-full">
              <thead>
                <tr className="bg-gray-100 text-center">
                  <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider w-1/6">姓名</th>
                  <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider w-1/6">關係</th>
                  <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider w-1/6">公司或行業別</th>
                  <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider w-1/6">職稱</th>
                  <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider w-1/6">管理</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="p-2">
                    <input
                      type="text"
                      name="name"
                      value={formData.familyStatus.name}
                      onChange={handleInputChange}
                      className="w-full px-2 py-1 border border-gray-300 rounded-md"
                    />
                  </td>
                  <td className="p-2">
                    <input
                      type="text"
                      name="relationship"
                      value={formData.familyStatus.relationship}
                      onChange={handleInputChange}
                      className="w-full px-2 py-1 border border-gray-300 rounded-md"
                    />
                  </td>
                  <td className="p-2">
                    <input
                      type="text"
                      name="company"
                      value={formData.familyStatus.company}
                      onChange={handleInputChange}
                      className="w-full px-2 py-1 border border-gray-300 rounded-md"
                    />
                  </td>
                  <td className="p-2">
                    <input
                      type="text"
                      name="title"
                      value={formData.familyStatus.title}
                      onChange={handleInputChange}
                      className="w-full px-2 py-1 border border-gray-300 rounded-md"
                    />
                  </td>
                  <td className="p-2">
                    <button
                      type="button"
                      onClick={handleAddFamilyStatus}
                      className="btn-new hover:btn-new px-3 py-1 text-white rounded-md"
                    >
                      新增
                    </button>
                  </td>
                </tr>
                {newFamilyStatus.map((status, index) => (
                  <tr key={index} className="border-b border-gray-200">
                    <td className="p-2">
                      {editingIndex === index ? (
                        <input
                          type="text"
                          value={status.name}
                          onChange={(e) => handleFamilyStatusChange(index, 'name', e.target.value)}
                          className="w-full px-2 py-1 border border-gray-300 rounded-md"
                        />
                      ) : (
                        status.name
                      )}
                    </td>
                    <td className="p-2">
                      {editingIndex === index ? (
                        <input
                          type="text"
                          value={status.relationship}
                          onChange={(e) => handleFamilyStatusChange(index, 'relationship', e.target.value)}
                          className="w-full px-2 py-1 border border-gray-300 rounded-md"
                        />
                      ) : (
                        status.relationship
                      )}
                    </td>
                    <td className="p-2">
                      {editingIndex === index ? (
                        <input
                          type="text"
                          value={status.company}
                          onChange={(e) => handleFamilyStatusChange(index, 'company', e.target.value)}
                          className="w-full px-2 py-1 border border-gray-300 rounded-md"
                        />
                      ) : (
                        status.company
                      )}
                    </td>
                    <td className="p-2">
                      {editingIndex === index ? (
                        <input
                          type="text"
                          value={status.title}
                          onChange={(e) => handleFamilyStatusChange(index, 'title', e.target.value)}
                          className="w-full px-2 py-1 border border-gray-300 rounded-md"
                        />
                      ) : (
                        status.title
                      )}
                    </td>
                    <td className="p-2">
                      {editingIndex === index ? (
                        <>
                          <button
                            type="button"
                            onClick={handleSaveFamilyStatus}
                            className="px-2 py-2 rounded-md btn-icon"
                          >
                            <FaRegSave />
                          </button>
                          <button
                            type="button"
                            onClick={() => handleCancelFamilyStatus()}
                            className="ml-2 px-2 py-2 rounded-md btn-icon"
                          >
                            <AiOutlineRollback />
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="button"
                            onClick={() => handleEditFamilyStatus(index)}
                            className="px-2 py-2 rounded-md btn-icon"
                          >
                            <FiEdit2 />
                          </button>
                          <button
                            type="button"
                            onClick={() => handleDeleteFamilyStatus(index)}
                            className="ml-2 px-2 py-2 rounded-md btn-icon"
                          >
                            <RiDeleteBinLine />
                          </button>
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div>
            <label className="block text-lg font-medium text-gray-700">緊急連絡人</label>
            <table className="w-full">
              <thead>
                <tr className="bg-gray-100 text-center">
                  <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider w-1/6">姓名</th>
                  <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider w-1/6">關係</th>
                  <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider w-1/6">電話</th>
                  <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider w-3/6">地址</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="p-2">
                    <input
                      type="text"
                      name="name"
                      value={emergencyContact.name}
                      onChange={handleInputEmergencyContactChange}
                      className="w-full px-2 py-1 border border-gray-300 rounded-md"
                    />
                  </td>
                  <td className="p-2">
                    <input
                      type="text"
                      name="relationship"
                      value={emergencyContact.relationship}
                      onChange={handleInputEmergencyContactChange}
                      className="w-full px-2 py-1 border border-gray-300 rounded-md"
                    />
                  </td>
                  <td className="p-2">
                    <input
                      type="text"
                      name="phone"
                      value={emergencyContact.phone}
                      onChange={handleInputEmergencyContactChange}
                      className="w-full px-2 py-1 border border-gray-300 rounded-md"
                    />
                  </td>
                  <td className="p-2">
                    <input
                      type="text"
                      name="address"
                      value={emergencyContact.address}
                      onChange={handleInputEmergencyContactChange}
                      className="w-full px-2 py-1 border border-gray-300 rounded-md"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="flex justify-center space-x-2">
            <button type="reset" className="px-4 py-2 border rounded-md text-gray-600 hover:bg-gray-100">
              清除
            </button>
            <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
              儲存
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default FamilyStatus;
