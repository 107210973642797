import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function PersonalDataAgreement() {
  const navigate = useNavigate();
  const [agree, setAgree] = useState(false);
  const [name, setName] = useState('王大明');
  return (
    <div className="bg-white shadow-sm rounded-lg p-6 max-w-screen-2xl mx-auto">
      <div>
        <div className="text-center font-semibold mb-4">台灣大車隊-個資同意書</div>
        <p>
        零壹科技股份有限公司（以下簡稱本公司），因配合台灣大車隊企業會員乘車無紙e化電子化管理執行之需求，需蒐集立書人個人資料，並依據本同意書之各項內容進行使用。為了保障您的權益及幫助您瞭解本會如何蒐集、使用及保護您個人資訊，請務必詳細的閱讀本同意書之各項內容。 
        </p>
        <p>
        一、立書人個人資料適用範圍：
        本公司為辦理台灣大車隊企業會員乘車無紙e化執行需求，必須取得您的個人資料，基於個人資料保護法及相關法令之規定，蒐集、處理及使用您的個人資料。
        </p>
        <p>
        二、立書人個人資料之蒐集及使用：
        依前項所述之事項，需要提供以下個人資料：姓名、手機號碼、公司e-mail、公司門禁卡卡號(或悠遊卡卡號)等以便識別該個人之資料。
        </p>
        <p>
        三、個人資料使用期間
        當您同意本公司使用您的個人資料時，期間自您同意當日起至第一項搜集目的消失為止。
        </p>
        <p>
        四、立書人之權益
        當本公司使用您的個人資料，您可依據個人資料保護法第3 條，向本公司執行下列權益：
        <br/>
        (一)查詢或請求閱覽。
        <br/>
        (二)請求製給複製本。
        <br/>
        (三)請求補充或更正。
        <br/>
        (四)請求停止蒐集、處理及利用。
        <br/>
        (五)請求刪除。
        </p>
        <p>
        當本公司保有您的個人資料時，基於我國「個人資料保護法」之規定。您可以透過E-MAIL或書面行使當事人權益，除基於符合「個人資料保護法」與其他相關法律規定外，本公司不會拒絕。
        </p>
      </div>
      <div className="text-right mt-4">
        <button
          onClick={() => navigate('/recruiter')}
          className="mr-2 px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        >
          確認
        </button>
        <button
          onClick={() => navigate('/recruiter')}
          className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        >
          返回
        </button>
      </div>
    </div>
  );
}

export default PersonalDataAgreement;
