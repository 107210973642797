import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaSortUp, FaSortDown } from 'react-icons/fa';
import { get, deleteById } from '../../services/api';

function Companies() {
  const [companies, setCompanies] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [companiesPerPage] = useState(25);
  const [totalCompanies, setTotalCompanies] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });

  const fetchCompanies = useCallback(async (search, page) => {
    setLoading(true);
    setError(null);
    
    try {
      const response = await get('/api/comm/v1/system/Corporations');
      console.log('API 完整響應:', response);

      if (response && Array.isArray(response)) {
        setCompanies(response);
        setTotalCompanies(response.total);
      } else {
        console.error('API 響應結構不符合預期:', response);
        setCompanies([]);
        setTotalCompanies(0);
        setError('獲取公司數據時出現問題');
      }
    } catch (error) {
      console.error('獲取公司列表失敗:', error);
      setError('獲取公司列表時發生錯誤');
      setCompanies([]);
      setTotalCompanies(0);
    } finally {
      setLoading(false);
    }
  }, [companiesPerPage]);

  useEffect(() => {
    fetchCompanies(searchTerm, currentPage);
  }, [fetchCompanies, currentPage]);

  const handleSearch = () => {
    setCurrentPage(1);
    fetchCompanies(searchTerm, 1);
  };

  const handleDelete = async (id) => {
    if (window.confirm('確定要刪除這家公司嗎？')) {
      try {
        await deleteById('/api/comm/v1/system/Corporation', id);
        fetchCompanies(searchTerm, currentPage);
      } catch (error) {
        console.error('刪除公司失敗:', error);
      }
    }
  };

  const totalPages = Math.ceil(totalCompanies / companiesPerPage);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  // 分頁按鈕渲染邏輯
  const renderPaginationButtons = () => {
    const maxButtons = 5;
    let startPage, endPage;

    if (totalPages <= maxButtons) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= Math.ceil(maxButtons / 2)) {
        startPage = 1;
        endPage = maxButtons;
      } else if (currentPage + Math.floor(maxButtons / 2) >= totalPages) {
        startPage = totalPages - maxButtons + 1;
        endPage = totalPages;
      } else {
        startPage = currentPage - Math.floor(maxButtons / 2);
        endPage = currentPage + Math.floor(maxButtons / 2);
      }
    }

    return (
      <>
        {startPage > 1 && (
          <>
            <button onClick={() => paginate(1)} className="mx-1 px-3 py-1 border rounded">1</button>
            {startPage > 2 && <span className="mx-1">...</span>}
          </>
        )}
        {Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i).map(number => (
          <button
            key={number}
            onClick={() => paginate(number)}
            className={`mx-1 px-3 py-1 border rounded ${currentPage === number ? 'bg-blue-500 text-white' : 'bg-white'}`}
          >
            {number}
          </button>
        ))}
        {endPage < totalPages && (
          <>
            {endPage < totalPages - 1 && <span className="mx-1">...</span>}
            <button onClick={() => paginate(totalPages)} className="mx-1 px-3 py-1 border rounded">{totalPages}</button>
          </>
        )}
      </>
    );
  };

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key) {
      if (sortConfig.direction === 'asc') {
        direction = 'desc';
      } else if (sortConfig.direction === 'desc') {
        direction = '';
      }
    }
    setSortConfig({ key, direction });
  };

  // 排序公司資料
  const sortedCompanies = () => {
    if (sortConfig.key) {
      return [...companies].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return companies; // 如果沒有排序，返回原始資料
  };

  if (loading) return <div>加載中...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="mx-auto">
      <div className="flex justify-between items-center">
        <div>
          <span className="text-gray-400">系統管理</span>
          <h1 className="text-2xl font-semibold text-gray-800">公司列表</h1>
        </div>
      </div>
      <div className="bg-white mt-4">
        <div className="overflow-x-auto">
          <table className="w-full border-collapse">
            <thead>
              <tr className="bg-gray-100 text-center select-none whitespace-nowrap">
                <th className="p-3 font-semibold text-sm text-gray-600 cursor-pointer text-center">
                  <div className="flex items-center justify-center" onClick={() => requestSort('taxIdNumber')}>
                    {sortConfig.key === 'taxIdNumber' && sortConfig.direction === 'asc' ? (
                      <FaSortUp className="mr-1" />
                    ) : sortConfig.key === 'taxIdNumber' && sortConfig.direction === 'desc' ? (
                      <FaSortDown className="mr-1" />
                    ) : null} {/* 當為預設狀態時不顯示圖示 */}
                    公司統編
                  </div>
                </th>
                <th className="p-3 font-semibold text-sm text-gray-600 cursor-pointer text-center">
                  <div className="flex items-center justify-center" onClick={() => requestSort('chineseName')}>
                    {sortConfig.key === 'chineseName' && sortConfig.direction === 'asc' ? (
                      <FaSortUp className="mr-1" />
                    ) : sortConfig.key === 'chineseName' && sortConfig.direction === 'desc' ? (
                      <FaSortDown className="mr-1" />
                    ) : null} {/* 當為預設狀態時不顯示圖示 */}
                    公司名稱
                  </div>
                </th>
                <th className="p-3 font-semibold text-sm text-gray-600 cursor-pointer text-center">
                  <div className="flex items-center justify-center" onClick={() => requestSort('address')}>
                    {sortConfig.key === 'address' && sortConfig.direction === 'asc' ? (
                      <FaSortUp className="mr-1" />
                    ) : sortConfig.key === 'address' && sortConfig.direction === 'desc' ? (
                      <FaSortDown className="mr-1" />
                    ) : null} {/* 當為預設狀態時不顯示圖示 */}
                    公司登記地址
                  </div>
                </th>
                <th className="p-3 font-semibold text-sm text-gray-600 cursor-pointer text-center">
                  <div className="flex items-center justify-center" onClick={() => requestSort('bank')}>
                    {sortConfig.key === 'bank' && sortConfig.direction === 'asc' ? (
                        <FaSortUp className="mr-1" />
                      ) : sortConfig.key === 'bank' && sortConfig.direction === 'desc' ? (
                        <FaSortDown className="mr-1" />
                      ) : null} {/* 當為預設狀態時不顯示圖示 */}
                    開戶銀行
                  </div>
                </th>
                <th className="p-3 font-semibold text-sm text-gray-600">報到通知提供之報到地點</th>
                <th className="p-3 font-semibold text-sm text-gray-600">管理</th>
              </tr>
            </thead>
            <tbody>
              {sortedCompanies().map((company, index) => (
                <tr key={company.id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                  <td className="p-3 text-sm text-gray-700 text-center">{company.taxIdNumber}</td>
                  <td className="p-3 text-sm text-gray-700">{company.chineseName}</td>
                  <td className="p-3 text-sm text-gray-700">{company.address}</td>
                  <td className="p-3 text-sm text-gray-700 text-center">{company.bank}</td>
                  <td className="p-3 text-sm text-gray-700">
                    {company.onBoardAddress.map((location, index) => (
                      <div key={index}>
                        {location.city}：{location.address}
                      </div>
                    ))}
                  </td>
                  <td className="p-3 text-sm whitespace-nowrap text-center">
                    <button onClick={() => navigate(`/companies/edit/${company.id}`)} className="btn-edit text-white px-3 py-1 rounded mr-2 hover:btn-edit transition duration-300">
                      編輯
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {totalPages > 1 && (
          <div className="mt-6 flex justify-center items-center">
            <button
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
              className="mx-1 px-3 py-1 border rounded text-gray-600 disabled:opacity-50 hover:bg-gray-100 transition duration-300"
            >
              ←
            </button>
            {renderPaginationButtons()}
            <button
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="mx-1 px-3 py-1 border rounded text-gray-600 disabled:opacity-50 hover:bg-gray-100 transition duration-300"
            >
              →
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Companies;
