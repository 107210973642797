import React, { useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';

function Declaration() {
  const [formData, setFormData] = useState({
    memo: '',
  });
  const navigate = useNavigate();
  const id = useLocation().search.split('interviewId=')[1];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('保存的數據:', formData);
    navigate(`/interviews`);
  }

  return (
    <div id="application-info" className="max-w-screen-2xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <div>
          <span className="text-gray-400">應徵者輸入應徵資料</span>
          <h1 className="text-2xl font-semibold text-gray-800">面試者填寫應徵者填寫聲明</h1>
        </div>
      </div>
      <div className="bg-white shadow-sm rounded-lg p-6 max-w-6xl">
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">填寫聲明</label>
            <textarea
              type="text"
              name="name"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              rows="6"
              style={{ minHeight: '100px' }}
              disabled
            >
              本人特此聲明，履歷表所陳述的資料皆屬事實且完整，如有虛假，應徵人於被雇用後，願接受無條件立即解雇之處分，絕無異議；個人願意徵信調查相關人員名單，同意貴公司可以調查本人的信用、雇用記錄及過去的工作狀況，如有需要亦可以調查本人薪資相關資料。
個資聲明：
本資料表所蒐集之個人資訊，將僅作為人才應徵申請之用，並遵守個人資料保護法相關規定，保障您的個人資料。
            </textarea>
          </div>
          <div className="flex justify-center space-x-2">
            <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
              同意以上聲明並送出
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Declaration;
