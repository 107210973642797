import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiEdit2 } from 'react-icons/fi';
import { RiDeleteBinLine } from "react-icons/ri";
import { FaRegSave } from "react-icons/fa";
import { AiOutlineRollback } from "react-icons/ai";

function EmployeeFamilyHealthInsuranceEnrollmentRecord() {
  const [newRecord, setNewRecord] = useState({
    'familyName' : '',
    'identity' : '',
    'rocBirthday' : '',
    'age' : '',
    'relation' : '',
    'insuranceCoverageDay' : '',
    'homeCity' : ''
  });
  const [editRecord, setEditRecord] = useState({
    'familyName' : '',
    'identity' : '',
    'rocBirthday' : '',
    'age' : '',
    'relation' : '',
    'insuranceCoverageDay' : '',
    'homeCity' : ''
  });
  const [records, setRecords] = useState([{
    'familyName' : '王小明',
    'identity' : 'A223456789',
    'rocBirthday' : '50/02/01',
    'age' : '63',
    'relation' : '母',
    'insuranceCoverageDay' : '113/9/1',
    'homeCity' : '台北市'
  }]);
  const [editIndex, setEditIndex] = useState(null);
  const navigate = useNavigate();

  const handleAdd = () => {
    if (newRecord) {
      setRecords([...records, newRecord]);
      setNewRecord('');
    }
  };

  const handleEditSave = () => {
    if (editIndex !== null) {
      const updatedRecords = [...records];
      updatedRecords[editIndex] = editRecord;
      setRecords(updatedRecords);
      setEditIndex(null);
    } else {
      setRecords([...records, editRecord]);
    }
    setEditRecord('');
  };

  const handleAddCancel = () => {
    setNewRecord('');
    setEditIndex(null);
  };

  const handleEdit = (index) => {
    setEditRecord(records[index]);
    setEditIndex(index);
  };

  const handleDelete = (index) => {
    if (window.confirm('您確定要刪除這個紀錄嗎？')) {
      const updatedRecords = records.filter((_, i) => i !== index);
      setRecords(updatedRecords);
    }
  };

  return (
    <div className="mx-auto">
      <div className="flex justify-between items-center mb-6">
        <div>
          <span className="text-gray-400"></span>
          <h1 className="text-2xl font-semibold text-gray-800">員工眷屬健保加退保紀錄表</h1>
        </div>
      </div>
      <div className='p-6 flex w-full'>
        <div className='flex-1'>
          <label className="text-gray-400">員工姓名</label>
          <div>王小明</div>
        </div>
        <div className='flex-1'>
          <label className="text-gray-400">身分證號</label>
          <div>A123456789</div>
        </div>
        <div className='flex-1'>
          <label className="text-gray-400">民國出生年月日</label>
          <div>80/1/1</div>
        </div>
      </div>
      <div className="bg-white shadow rounded-lg p-6">
        <div className="overflow-x-auto">
          <table className="w-full border-collapse border border-gray-300">
            <thead className="bg-gray-50">
              <tr className="text-center">
                <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider">眷屬姓名</th>
                <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider">身分證字號</th>
                <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider">民國出生年月日</th>
                <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider">年齡</th>
                <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider">關係</th>
                <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider">加保日</th>
                <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider">戶籍縣市</th>
                <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider">管理</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              <tr className="text-center">
                <td className="px-4 py-2">
                  <input
                    type="text"
                    className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={newRecord.familyName}
                    onChange={(e) => setNewRecord({...newRecord, familyName: e.target.value})}
                  />
                </td>
                <td className="px-4 py-2">
                  <input
                    type="text"
                    className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={newRecord.identity}
                    onChange={(e) => setNewRecord({...newRecord, identity: e.target.value})}
                  />
                </td>
                <td className="px-4 py-2">
                  <input
                    type="text"
                    className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={newRecord.rocBirthday}
                    onChange={(e) => setNewRecord({...newRecord, rocBirthday: e.target.value})}
                  />
                </td>
                <td className="px-4 py-2">
                  <input
                    type="text"
                    className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={newRecord.age}
                    onChange={(e) => setNewRecord({...newRecord, age: e.target.value})}
                  />
                </td>
                <td className="px-4 py-2">
                  <input
                    type="text"
                    className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={newRecord.relation}
                    onChange={(e) => setNewRecord({...newRecord, relation: e.target.value})}
                  />
                </td>
                <td className="px-4 py-2">
                  <input
                    type="text"
                    className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={newRecord.insuranceCoverageDay}
                    onChange={(e) => setNewRecord({...newRecord, insuranceCoverageDay: e.target.value})}
                  />
                </td>
                <td className="px-4 py-2">
                  <select
                    className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={newRecord.homeCity}
                    onChange={(e) => setNewRecord({...newRecord, homeCity: e.target.value})}
                  >
                    <option value="">戶籍縣市</option>
                    <option value="台北市">台北市</option>
                  </select>
                </td>
                <td className="px-4 py-2">
                  <button
                    onClick={handleAdd}
                    className="btn-new hover:btn-new px-3 py-1 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  >
                    新增
                  </button>
                </td>
              </tr>
              {records.map((record, index) => (
                <tr key={index}>
                  <td className="px-4 py-2">
                    {editIndex === index ? (
                      <input
                        type="text"
                        className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        value={editRecord.familyName}
                        onChange={(e) => setEditRecord({...editRecord, familyName: e.target.value})}
                      />
                    ) : (
                      record.familyName
                    )}
                  </td>
                  <td className="px-4 py-2">
                    {editIndex === index ? (
                      <input
                        type="text"
                        className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        value={editRecord.identity}
                        onChange={(e) => setEditRecord({...editRecord, identity: e.target.value})}
                      />
                    ) : (
                      record.identity
                    )}
                  </td>
                  <td className="px-4 py-2">
                    {editIndex === index ? (
                      <input
                        type="text"
                        className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        value={editRecord.rocBirthday}
                        onChange={(e) => setEditRecord({...editRecord, rocBirthday: e.target.value})}
                      />
                    ) : (
                      record.rocBirthday
                    )}
                  </td>
                  <td className="px-4 py-2">
                    {editIndex === index ? (
                      <input
                        type="text"
                        className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        value={editRecord.age}
                        onChange={(e) => setEditRecord({...editRecord, age: e.target.value})}
                      />
                    ) : (
                      record.age
                    )}
                  </td>
                  <td className="px-4 py-2">
                    {editIndex === index ? (
                      <input
                        type="text"
                        className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        value={editRecord.relation}
                        onChange={(e) => setEditRecord({...editRecord, relation: e.target.value}  )}
                      />
                    ) : (
                      record.relation
                    )}
                  </td>
                  <td className="px-4 py-2">
                    {editIndex === index ? (
                      <input
                        type="text"
                        className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        value={editRecord.insuranceCoverageDay}
                        onChange={(e) => setEditRecord({...editRecord, insuranceCoverageDay: e.target.value})}
                      />
                    ) : (
                      record.insuranceCoverageDay
                    )}
                  </td>
                  <td className="px-4 py-2">
                    {editIndex === index ? (
                      <select
                        className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        value={editRecord.homeCity}
                        onChange={(e) => setEditRecord({...editRecord, homeCity: e.target.value})}
                      >
                        <option value="">戶籍縣市</option>
                        <option value="台北市">台北市</option>
                      </select>
                    ) : (
                      record.homeCity
                    )}
                  </td>
                  <td className="px-4 py-2 whitespace-nowrap">
                    {editIndex === index ? (
                      <div>
                        <button
                          onClick={handleEditSave}
                          className="px-2 py-2 rounded-md btn-icon"
                        >
                          <FaRegSave />
                        </button>
                        <button
                          onClick={handleAddCancel}
                          className="ml-2 px-2 py-2 rounded-md btn-icon"
                        >
                          <AiOutlineRollback />
                        </button>
                      </div>
                    ) : (
                      <div>
                        <button
                          onClick={() => handleEdit(index)}
                          className="px-2 py-2 rounded-md transition duration-300 btn-icon"
                        >
                          <FiEdit2 />
                        </button>
                        <button
                          onClick={() => handleDelete(index)}
                          className="px-2 py-2 ml-2 rounded-md btn-icon"
                        >
                          <RiDeleteBinLine />
                        </button>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="text-right">
            <p>加保 {records.length} 人</p>
          </div>
          <div className="text-right mt-4">
            <button
              onClick={() => navigate('/recruiter')}
              className="btn-save hover:btn-save px-3 py-1 text-white rounded-md"
            >
              確認
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmployeeFamilyHealthInsuranceEnrollmentRecord;
