import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TaxExemptionFormMenu from './TaxExemptionFormMenu';
import TaxExemptionFormIncomeRecipient from './TaxExemptionFormIncomeRecipient';

function TaxExemptionChildrenForm() {
  const [persons, setPersons] = useState([
    { id: 1, name: '劉小芬', title: '子', birthDate: '83/08/01', idNumber: 'A123456789', address: '台北市信義區', condition: '1' },
    { id: 2, name: '劉小芬', title: '子', birthDate: '83/08/01', idNumber: 'A123456789', address: '台北市信義區', condition: '1' }
  ]);
  const [newTemplate, setNewTemplate] = useState('');
  const [templates, setTemplates] = useState([
    { id: 1, name: '錄取通知模板', isEditing: false, originalName: '錄取通知模板' }
  ]);
  const navigate = useNavigate();

  const handleAdd = () => {
    if (newTemplate.trim()) {
      setTemplates([...templates, { id: Date.now(), name: newTemplate, isEditing: false, originalName: newTemplate }]);
      setNewTemplate('');
    }
  };

  const handleEdit = (id) => {
    setPersons(persons.map(person =>
      person.id === id ? { ...person, isEditing: true } : person
    ));
  };

  const handleSave = (id) => {
    setPersons(persons.map(person =>
      person.id === id ? { ...person, isEditing: false, originalName: person.name } : person
    ));
  };

  const handleCancel = (id) => {
    setPersons(persons.map(person =>
      person.id === id ? { ...person, isEditing: false, name: person.originalName } : person
    ));
  };

  const handleDelete = (id) => {
    const personToDelete = persons.find(person => person.id === id);
    if (window.confirm(`確定要刪除「${personToDelete.name}」嗎？`)) {
      setPersons(persons.filter(person => person.id !== id));
    }
  };

  return (
    <div className="mx-auto">
      <div className="flex justify-between items-center mb-6">
        <div>
          <span className="text-gray-400"></span>
          <h1 className="text-2xl font-semibold text-gray-800">員工薪資所得受領人免稅額申報表</h1>
        </div>
      </div>
      <div className="bg-white shadow rounded-lg p-6">
        <TaxExemptionFormMenu activeTab="summary" />
        <div>
          <TaxExemptionFormIncomeRecipient />
          <table className="w-full border-collapse border border-gray-300 mt-4">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">姓名</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">稱謂</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">民國出生年月日</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">身分證字號統一編號</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">現在地址</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">符合之條件</th>
              </tr>
            </thead>        
            <tbody>
              {persons.map(person => (
                <tr key={person.id}>
                  <td className="border px-4 py-2">
                    <span>{person.name}</span>
                  </td>
                  <td className="border px-4 py-2">
                    <span>{person.title}</span>
                  </td>
                  <td className="border px-4 py-2">
                    <span>{person.birthDate}</span>
                  </td>
                  <td className="border px-4 py-2">
                    <span>{person.idNumber}</span>  
                  </td>
                  <td className="border px-4 py-2">
                    <span>{person.address}</span>
                  </td>
                  <td className="border px-4 py-2">
                    <span>{person.condition}</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="mt-4 text-right">
          合於減除扶養親屬免稅額之受扶養親屬（共計{persons.length}人）
        </div>
      </div>
      <div className="flex justify-center space-x-2 mt-4">
        <button type="button" onClick={() => navigate(`/recruiter`)} className="px-4 py-2 border rounded-md text-gray-600 bg-blue-500 text-white hover:bg-blue-600">
          儲存並返回
        </button>
      </div>
    </div>
  );
}

export default TaxExemptionChildrenForm;