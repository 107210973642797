import React, { useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';

function HRM() {
  const [formData, setFormData] = useState({
    memo: '',
  });
  const navigate = useNavigate();
  const id = useLocation().search.split('interviewId=')[1];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('保存的數據:', formData);
    navigate(`/interviews`);
  }

  return (
    <div id="application-info" className="max-w-screen-2xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <div>
          <span className="text-gray-400">報到管理</span>
          <h1 className="text-2xl font-semibold text-gray-800">轉HRM</h1>
        </div>
      </div>
      <div className="bg-white shadow-sm rounded-lg p-6 max-w-6xl">
        <table className="w-full border-collapse border border-gray-300 mt-4">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">完成資料</th>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">最終編輯時間</th>
            </tr>
          </thead>   
          <tbody>
            <tr>
              <td className="border px-4 py-2">員工薪資所得受領人免稅額申報表</td>
              <td className="border px-4 py-2">2024-09-04</td>
            </tr>
            <tr>
              <td className="border px-4 py-2">員工眷屬健保加退保紀錄表</td>
              <td className="border px-4 py-2">2024-09-04</td>
            </tr>
            <tr>
              <td className="border px-4 py-2">勞工退休金制度自提率徵詢表</td>
              <td className="border px-4 py-2">2024-09-04</td>
            </tr>
            <tr>
              <td className="border px-4 py-2">工程師證照明細</td>
              <td className="border px-4 py-2">2024-09-04</td>
            </tr>
            <tr>
              <td className="border px-4 py-2">設定英文姓名</td>
              <td className="border px-4 py-2">2024-09-04</td>
            </tr>
            <tr>
              <td className="border px-4 py-2">自我介紹</td>
              <td className="border px-4 py-2">2024-09-04</td>
            </tr>
            <tr>
              <td className="border px-4 py-2">銀行存摺</td>
              <td className="border px-4 py-2">2024-09-04</td>
            </tr>
            <tr>
              <td className="border px-4 py-2">國民身份證/居留證</td>
              <td className="border px-4 py-2"></td>
            </tr>
            <tr>
              <td className="border px-4 py-2"><a href="#" target="_blank" rel="noopener noreferrer" className="text-blue-500">最高學歷證明</a></td>
              <td className="border px-4 py-2">2024-09-04</td>
            </tr>
            <tr>
              <td className="border px-4 py-2"><a href="#" target="_blank" rel="noopener noreferrer" className="text-blue-500">退伍證明</a></td>
              <td className="border px-4 py-2">2024-09-04</td>
            </tr>
            <tr>
              <td className="border px-4 py-2"><a href="#" target="_blank" rel="noopener noreferrer" className="text-blue-500">前一公司之薪資證明</a></td>
              <td className="border px-4 py-2">2024-09-04</td>
            </tr>
            <tr>
              <td className="border px-4 py-2"><a href="#" target="_blank" rel="noopener noreferrer" className="text-blue-500">前一公司之離職證明書</a></td>
              <td className="border px-4 py-2">2024-09-04</td>
            </tr>
            <tr>
              <td className="border px-4 py-2"><a href="#" target="_blank" rel="noopener noreferrer" className="text-blue-500">前一公司之勞健保轉出單</a></td>
              <td className="border px-4 py-2">2024-09-04</td>
            </tr>
            <tr>
              <td className="border px-4 py-2">新進員工體格檢查報告一份</td>
              <td className="border px-4 py-2"></td>
            </tr>
            <tr>
              <td className="border px-4 py-2"><a href="#" target="_blank" rel="noopener noreferrer" className="text-blue-500">專業認證資格證明文件</a></td>
              <td className="border px-4 py-2">2024-09-04</td>
            </tr>
            <tr>
              <td className="border px-4 py-2"><a href="#" target="_blank" rel="noopener noreferrer" className="text-blue-500">其他國家考試或技能檢定合格證明文件</a></td>
              <td className="border px-4 py-2">2024-09-04</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="flex justify-center space-x-2 mt-4">
        <button type="button" className="px-4 py-2 border rounded-md text-gray-600 bg-blue-500 text-white hover:bg-blue-600">
          轉HRM
        </button>
        <button type="button" onClick={() => navigate(`/admissions`)} className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-md ">
          返回
        </button>
      </div>
    </div>
  );
}

export default HRM;
