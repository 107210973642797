import React, { useState, useEffect } from 'react';
import Header from './Header';
import { Link, Outlet, useNavigate, useLocation } from 'react-router-dom';
import { 
  FaChevronDown, FaChevronRight, FaBuilding, FaUserTie, FaClipboardList, 
  FaUsers, FaUsersCog, FaListAlt, FaComments, FaSearchPlus, FaUserCheck,
  FaUserPlus, FaEnvelope, FaIdCard, FaUserShield, FaCog
} from 'react-icons/fa';

function Layout() {
  const logo = require('../../assets/zerone-logo.png');
  const isDesktop = window.innerWidth > 1024;
  const [isSidebarOpen, setIsSidebarOpen] = useState(isDesktop);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // 新增狀態來控制各部分的展開/收合
  const [expandedSections, setExpandedSections] = useState({
    systemManagement: true,
    recruitmentManagement: true,
    hireManagement: true,
    onboardingManagement: true,
    memberManagement: true
  });

  useEffect(() => {
    const checkScreenSize = () => {
      const isSmall = window.innerWidth < 1024;
      setIsSmallScreen(isSmall);
      if (!isSmall) {
        setIsSidebarOpen(true);
      } else {
        setIsSidebarOpen(false);
      }
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleNavigation = (path) => {
    navigate(path);
    if (isSmallScreen) {
      setIsSidebarOpen(false);
    }
  };

  const isActive = (path) => {
    return location.pathname.includes(path);
  };

  const menuItemClass = (path) => `
    block py-2 px-4 text-sm transition duration-200 ease-in-out w-full text-left flex items-center
    ${isActive(path) 
      ? 'bg-gray-100 active' 
      : 'text-gray-700 hover:bg-gray-100 hover:text-gray-900'}
  `;

  const toggleSection = (section) => {
    setExpandedSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  return (
    <div className="flex h-screen bg-gray-100 overflow-hidden">
      {/* 左側選單 */}
      <nav className={`
        ${isSidebarOpen ? 'translate-x-0 lg:relative lg:transform-none' : '-translate-x-full'}
        shadow-lg transition-transform duration-300 ease-in-out
        fixed inset-y-0 left-0 z-30 w-64 overflow-y-auto
      `}>
        <div className="p-4 cursor-pointer" onClick={() => handleNavigation('/')}>
          <img src={logo} alt="零壹科技eHRM" width={100} className='mx-auto' />
          <h1 className="font-bold text-center text-xl text-gray-800">eHRM</h1>
        </div>
        <ul className="mt-4 space-y-2">
          <li>
            <div 
              className="flex items-center justify-between px-4 py-2 text-sm text-gray-500 cursor-pointer hover:bg-gray-100"
              onClick={() => toggleSection('systemManagement')}
            >
              <span className="flex items-center">
                <FaCog className="mr-2" /> 系統管理
              </span>
              {expandedSections.systemManagement ? <FaChevronDown /> : <FaChevronRight />}
            </div>
            {expandedSections.systemManagement && (
              <ul className="ml-9">
                <li>  
                  <button onClick={() => handleNavigation('/companies')} className={menuItemClass('/companies')}>
                    公司列表
                  </button>
                </li>
                <li>
                  <button onClick={() => handleNavigation('/interview-evaluation')} className={menuItemClass('/interview-evaluation')}>
                    面試評核項目列表
                  </button>
                </li>
                <li>
                  <button onClick={() => handleNavigation('/applicant-sources')} className={menuItemClass('/applicant-sources')}>
                    面試來源
                  </button>
                </li>
                {/* <li>
                  <button onClick={() => handleNavigation('/group-insurance-plan')} className={menuItemClass('/group-insurance-plan')}>
                    <FaUsers className="mr-2" />
                    團保方案管理
                  </button>
                </li> */}
                <li>
                  <button onClick={() => handleNavigation('/letter-templates')} className={menuItemClass('/letter-templates')}>
                    信件模板
                  </button>
                </li>
                <li>  
                  <button onClick={() => handleNavigation('/users')} className={menuItemClass('/users')}>
                    使用者列表
                  </button>
                </li>
                <li>  
                  <button onClick={() => handleNavigation('/roles')} className={menuItemClass('/roles')}>
                    角色編輯
                  </button>
                </li>
              </ul>
            )}
          </li>
          <li>
            <div 
              className="flex items-center justify-between px-4 py-2 text-sm text-gray-500 cursor-pointer hover:bg-gray-100"
              onClick={() => toggleSection('recruitmentManagement')}
            >
              <span className="flex items-center">
                <FaListAlt className="mr-2" /> 招募管理
              </span>
              {expandedSections.recruitmentManagement ? <FaChevronDown /> : <FaChevronRight />}
            </div>
            {expandedSections.recruitmentManagement && (
              <ul className="ml-9">
                <li>  
                  <button onClick={() => handleNavigation('/interviews')} className={menuItemClass('/interviews')}>
                    面試列表
                  </button>
                </li>
              </ul>
            )}
          </li>
          <li>
            <div 
              className="flex items-center justify-between px-4 py-2 text-sm text-gray-500 cursor-pointer hover:bg-gray-100"
              onClick={() => toggleSection('hireManagement')}
            >
              <span className="flex items-center">
                <FaUserCheck className="mr-2" /> 錄取管理
              </span>
              {expandedSections.hireManagement ? <FaChevronDown /> : <FaChevronRight />}
            </div>
            {expandedSections.hireManagement && (
              <ul className="ml-9">
                <li>  
                  <button onClick={() => handleNavigation('/admissions')} className={menuItemClass('/admissions')}>
                    錄取列表
                  </button>
                </li>
                <li>
                  <button onClick={() => handleNavigation('/recruiter')} className={menuItemClass('/recruiter')}>
                    錄取管理(錄用者畫面)
                  </button>
                </li>
              </ul>
            )}
          </li>
          <li>
            <div 
              className="flex items-center justify-between px-4 py-2 text-sm text-gray-500 cursor-pointer hover:bg-gray-100"
              onClick={() => toggleSection('onboardingManagement')}
            >
              <span className="flex items-center">
                <FaIdCard className="mr-2" /> 報到管理
              </span>
              {expandedSections.onboardingManagement ? <FaChevronDown /> : <FaChevronRight />}
            </div>
            {expandedSections.onboardingManagement && (
              <ul className="ml-9">
                <li>  
                  <button onClick={() => handleNavigation('/onboards')} className={menuItemClass('/onboards')}>
                    報到列表
                  </button>
                </li>
                <li>
                  <button onClick={() => handleNavigation('/onboard-recruiter')} className={menuItemClass('/onboard-recruiter')}>
                    報到管理(錄用者畫面)
                  </button>
                </li>
              </ul>
            )}
          </li>
        </ul>
      </nav>

      {/* 遮罩層 */}
      {isSidebarOpen && isSmallScreen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-20 lg:hidden" 
          onClick={toggleSidebar}
        ></div>
      )}

      {/* 主要內容區域 */}
      <main className={`flex-1 flex flex-col overflow-hidden transition-all duration-300`}>
        <Header toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} />
        <div className="flex-1 overflow-auto p-6 bg-white">
          <Outlet />
        </div>
      </main>
    </div>
  );
}

export default Layout;
