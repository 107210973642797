import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getById, post, putById } from '../../services/api';
import { FaRegSave } from 'react-icons/fa';
import { FiEdit2 } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { AiOutlineRollback } from 'react-icons/ai';

function CompanyForm() {
  const [formData, setFormData] = useState({
    taxIdNumber: '',
    chineseName: '',
    address: '',
    onBoardAddress: [
      {
        city: '',
        address: ''
      }
    ],
  });
  const [interviews, setInterviews] = useState([]);
  const [newLocation, setNewLocation] = useState({ city: '', address: '' });
  const [editingIndex, setEditingIndex] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();

  const [banks] = useState([
    '台灣銀行', '中國信託銀行', '國泰世華銀行', '玉山銀行', '台北富邦銀行'
  ]);

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id]);

  const fetchData = async (id) => {
    setLoading(true);
    try {
      const data = await getById("/api/ehrm/v1/Interview", id);
      console.log(data);
      setFormData(data);
    } catch (error) {
      setError('獲取數據失敗');
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleNewLocationChange = (e) => {
    const { name, value } = e.target;
    setNewLocation(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      if (id) {
        await putById(id, formData);
      } else {
        await post(formData);
      }
      navigate('/companies');
    } catch (error) {
      setError('保存公司數據失敗');
    } finally {
      setLoading(false);
    }
  };

  const handleAddLocation = () => {
    if (newLocation.city && newLocation.address) {
      setFormData(prev => ({
        ...prev,
        onBoardAddress: [...prev.onBoardAddress, newLocation]
      }));
      setNewLocation({ city: '', address: '' });
    }
  };

  const handleLocationChange = (index, field, value) => {
    const updatedLocations = formData.onBoardAddress.map((loc, i) => 
      i === index ? { ...loc, [field]: value } : loc
    );
    setFormData({ ...formData, onBoardAddress: updatedLocations });
  };

  const handleEditLocation = (index) => {
    setEditingIndex(index);
  };

  const handleSaveLocation = () => {
    setEditingIndex(-1);
  };

  const handleDeleteLocation = (index) => {
    if (window.confirm('確定要刪除嗎？')) {
      try {
        const updatedLocations = formData.onBoardAddress.filter((_, i) => i !== index);
        setFormData({ ...formData, onBoardAddress: updatedLocations });
      } catch (error) {
        console.error('刪除公司失敗:', error);
      }
    }
  };

  const handleCancelLocation = () => {
    setEditingIndex(-1);
  };

  // 添加處理銀行選擇的函數
  const handleBankSelect = (selectedBank) => {
    setFormData({ ...formData, bank: selectedBank });
  };

  if (loading) return <div>加載中...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="mx-auto">
      <div className="flex justify-between items-center mb-6">
        <div>
          <span className="text-gray-400">面試管理</span>
          <h1 className="text-2xl font-semibold text-gray-800">{id ? '編輯' : '新增'}面試</h1>
        </div>
      </div>
      <div className="max-w-6xl">
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <h3 className="text-lg font-semibold text-gray-800 mb-4">基本資料</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Mail</label>
                <input
                  type="email"
                  name="email"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  // ... other props ...
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">姓名</label>
                <input
                  type="text"
                  name="name"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  // ... other props ...
                />
              </div>
            </div>
          </div>

          <div>
            <h3 className="text-lg font-semibold text-gray-800 mb-4">面談資料</h3>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">公司</label>
                <select name="company" className="w-full px-3 py-2 border border-gray-300 rounded-md">
                  <option>公司1</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">部門</label>
                <select name="department" className="w-full px-3 py-2 border border-gray-300 rounded-md">
                  <option>資訊部門</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">應徵職務</label>
                <input
                  type="text"
                  name="jobTitle"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  // ... other props ...
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">職務類別</label>
                <select name="jobCategory" className="w-full px-3 py-2 border border-gray-300 rounded-md">
                  {/* 下拉選單選項 */}
                </select>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 py-3">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">履歷可填寫日期</label>
                <div className="flex space-x-4">
                  <input
                    type="date"
                    name="resumeDate"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md"
                    // ... other props ...
                  />
                  <input
                    type="date"
                    name="resumeDateEnd"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md"
                    // ... other props ...
                  />
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">應徵來源</label>
                <div className="flex space-x-4">
                  <select name="source" className="w-full px-3 py-2 border border-gray-300 rounded-md">
                    {/* 下拉選單選項 */}
                  </select>
                  <input
                    type="text"
                    name="resumeDateEnd"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md"
                    // ... other props ...
                  />
                </div>
              </div>
            </div>
          </div>

          {!formData.id && (
            <div>
              <div className="bg-gray-50 p-4 rounded-md">
                <table className="w-full">
                  <thead>
                    <tr className="bg-gray-100">
                      <th className="p-2 text-left font-medium text-gray-700 w-1/4">初/複試</th>
                      <th className="p-2 text-left font-medium text-gray-700 w-1/4">主管姓名</th>
                      <th className="p-2 text-left font-medium text-gray-700 w-1/4">面談時間</th>
                      <th className="p-2 text-left font-medium text-gray-700 w-1/4">管理</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="p-2">
                        <div className="flex flex-row justify-between">
                          <div>
                            <input
                              type="radio"
                              name="interviewType"
                              value="HR"
                              onChange={handleNewLocationChange}
                            />
                            <span className="px-1">HR</span>
                          </div>
                          <div>
                            <input
                              type="radio"
                              name="interviewType"
                              value="初"
                              onChange={handleNewLocationChange}
                            />
                            <span className="px-1">初</span>
                          </div>
                          <div>
                            <input
                              type="radio"
                              name="interviewType"
                              value="複"
                              onChange={handleNewLocationChange}
                            />
                            <span className="px-1">複</span>
                          </div>
                        </div>
                      </td>
                      <td className="p-2">
                        <select name="company" className="w-full px-3 py-2 border border-gray-300 rounded-md">
                          {/* 下拉選單選項 */}
                        </select>
                      </td>
                      <td className="p-2">
                        <input
                          type="text"
                          name="address"
                          value={newLocation.address}
                          onChange={handleNewLocationChange}
                          className="w-full px-2 py-1 border border-gray-300 rounded-md"
                        />
                      </td>
                      <td className="p-2">
                        <button
                          type="button"
                          onClick={handleAddLocation}
                          className="btn-new hover:btn-new px-3 py-1 text-white rounded-md transition duration-300"
                        >
                          新增
                        </button>
                      </td>
                    </tr>
                    {interviews.map((interview, index) => (
                      <tr key={index} className="border-b border-gray-200">
                        <td className="p-2">
                          {editingIndex === index ? (
                            <input
                              type="text"
                              value={interview.city}
                              onChange={(e) => handleLocationChange(index, 'city', e.target.value)}
                              className="w-full px-2 py-1 border border-gray-300 rounded-md"
                            />
                          ) : (
                            interview.city
                          )}
                        </td>
                        <td className="p-2">
                          {editingIndex === index ? (
                            <input
                              type="text"
                              value={interview.address}
                              onChange={(e) => handleLocationChange(index, 'address', e.target.value)}
                              className="w-full px-2 py-1 border border-gray-300 rounded-md"
                            />
                          ) : (
                            interview.address
                          )}
                        </td>
                        <td className="p-2">
                          {editingIndex === index ? (
                            <input
                              type="text"
                              value={interview.address}
                              onChange={(e) => handleLocationChange(index, 'address', e.target.value)}
                              className="w-full px-2 py-1 border border-gray-300 rounded-md"
                            />
                          ) : (
                            interview.address
                          )}
                        </td>
                        <td className="p-2">
                          {editingIndex === index ? (
                            <>
                              <button
                                type="button"
                                onClick={handleSaveLocation}
                                className="px-3 py-1 rounded-md btn-icon"
                              >
                                <FaRegSave />
                              </button>
                              <button
                                type="button"
                                onClick={handleCancelLocation}
                                className="px-3 py-1 rounded-md btn-icon"
                              >
                                <AiOutlineRollback />
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                type="button"
                                onClick={() => handleEditLocation(index)}
                                className="px-3 py-1 rounded-md btn-icon"
                              >
                                <FiEdit2 />
                              </button>
                              <button
                                type="button"
                                onClick={() => handleDeleteLocation(index)}
                                className="ml-2 px-3 py-1 rounded-md btn-icon"
                              >
                                <RiDeleteBinLine />
                              </button>
                            </>
                          )}

                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          {!formData.id && (
            <div>
              <div className="mb-4">
                <div className="flex justify-between items-center">
                  <h3 className="text-lg font-semibold text-gray-800 mb-4">通知訊息</h3>
                  <select
                    id="notificationTemplate"
                    name="notificationTemplate"
                    className="px-3 py-2 border border-gray-300 rounded-md"
                  >
                    <option value="">面試通知模板</option>
                    <option value="template1">模板1</option>
                    <option value="template2">模板2</option>
                    <option value="template3">模板3</option>
                  </select>
                </div>
              </div>
              <div className="mb-4">
                <textarea
                  id="customNotification"
                  name="customNotification"
                  rows={4}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm p-2"
                  placeholder="輸入自定義通知訊息"
                />
              </div>
            </div>
          )}


          <div className="flex justify-center space-x-3">
            {!formData.id && (
              <button type="submit" className="btn-save-and-send hover:btn-save-and-send text-white px-4 py-2 rounded-md transition duration-300">
                儲存&傳送通知
              </button>
            )}
            <button type="submit" className="btn-new hover:btn-new px-4 py-2 rounded-md transition duration-300">
              儲存
            </button>
            <button type="button" onClick={() => navigate('/interviews')} className="btn-return hover:btn-return px-4 py-2 rounded-md transition duration-300">
              返回
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CompanyForm;
